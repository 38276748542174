import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HistoryService {
  baseUrl = environment.apiUrl + "/reports";
  token = localStorage.getItem("token");
  constructor(private http: HttpClient) {}

  getWinLose(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/win-loss/player/history`, {
      headers: httpHeaders,
    });
  }

  getWinLoseByDate(date: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/win-loss/player/history?date=${date}`, {
      headers: httpHeaders,
    });
  }

  getWinLoseByGame(date: any, game: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(
      this.baseUrl +
        `/v1/win-loss/player/history?date=${date}&game_provider_code=${game}`,
      { headers: httpHeaders }
    );
  }
}
