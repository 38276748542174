import { Component } from "@angular/core";
import { AuthService } from "src/app/_service/auth.service";
import { WheelService } from "src/app/_service/wheel.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-wheel",
  templateUrl: "./wheel.component.html",
  styleUrls: ["./wheel.component.scss"],
})
export class WheelComponent {
  private isLogin: boolean = false;
  private playerId: string = "";
  private username: string = "";
  private agentCode: string = "";
  public ticket: number = 0;
  public currentIndex: number = 0;
  public cumulativeTurn: number = 100;
  public configurationWheel: any[] = [];
  private loading: boolean = false;
  private closeModalTimeoutId: any = null

  constructor(
    private authService: AuthService,
    private wheelService: WheelService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.isLogin = this.authService.loggedIn();
    if (this.isLogin) {
      const user = this.authService.getDecodeToken();
      this.playerId = user.id;
      this.username = user.preferred_username.split(
        environment.agentCode.toLowerCase()
      )[1];
      this.agentCode = environment.agentCode;
      this.handleGetConfigurationWheel();
      this.handleGetPlayerTicketRemaining();
    }
  }

  public handleGetPlayerTicketRemaining() {
    this.wheelService
      .getPlayerTicketRemaining({
        username: this.username,
        code: this.agentCode,
      })
      .subscribe((resp) => {
        if (resp) {
          this.ticket = resp.data?.ticket ?? 0;
        }
      });
  }

  public handleGetConfigurationWheel() {
    this.wheelService.getConfigurationWheel().subscribe((resp) => {
      if (resp) {
        resp.dataClient.forEach((item: any) => {
          this.configurationWheel.push(item);
        });
      }
    });
  }

  public async handleGetWheelDraw() {
    if (this.isLogin && this.ticket && !this.loading) {
      this.loading = true;
      this.wheelService
        .getWheelDraw({ player_id: this.playerId, username: this.username })
        .subscribe(async (resp) => {
          if (resp) {
            const configurationIndex =
              this.configurationWheel.findIndex(
                (config) => config.id === resp.configuration_id
              ) ?? -1;
            await this.turning(configurationIndex);
          }
        });
    }
  }

  public getTransform(index: number) {
    return `translateX(-50%) rotate(${-index * 0.125})turn`;
  }

  public async turning(configurationIndex: number) {
    if (configurationIndex === -1) return;
    this.handleGetPlayerTicketRemaining();
    const roulette = document.getElementById("roulette");
    const resultModal = document.getElementById("result-modal");
    if (roulette) {
      const moveCount =
        configurationIndex > this.currentIndex
          ? 8 - (configurationIndex - this.currentIndex)
          : this.currentIndex - configurationIndex;
      const turn = this.cumulativeTurn + moveCount * 0.125;
      roulette.style.transform = `rotate(${turn}turn)`;
      roulette.classList.add("turning");
      this.currentIndex = configurationIndex;
      this.cumulativeTurn = turn + 100;

      const timeoutId = setTimeout(() => {
        resultModal?.classList.remove("result_modal__hide");

        this.closeModalTimeoutId = setTimeout(() => {
          resultModal?.classList.add("result_modal__hide");
          this.loading = false;

          clearTimeout(this.closeModalTimeoutId as number);
        }, 8000);

        clearTimeout(timeoutId);
      }, 6000);
    }
  }

  public closeResultModal() {
    const resultModal = document.getElementById("result-modal");
    resultModal?.classList.add("result_modal__hide");
    this.loading = false;
    clearTimeout(this.closeModalTimeoutId as number);
  }
}
