import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { UtilService } from "src/app/_service/util.service";
import { PlayersService } from "src/app/_service/players.service";

@Component({
  selector: "app-table-commissions",
  templateUrl: "./table-commissions.component.html",
  styleUrls: ["./table-commissions.component.scss"],
})
export class TableCommissionsComponent implements OnInit {
  searchForm!: UntypedFormGroup;
  dataSource: any[] = [];
  total: any;
  startValue: Date | null = null;
  endValue: Date | null = null;
  user: any;
  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private message: NzMessageService,
    private utilService: UtilService,
    private playersService: PlayersService
  ) {}
  format = (date: Date) => {
    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    const year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${year}-${month}-${day}`;
  };

  ngOnInit(): void {
    this.startValue = new Date(
      this.format(new Date(new Date().setDate(new Date().getDate() - 1))) +
        " 00:00:00"
    );
    this.endValue = new Date(
      this.format(new Date(new Date().setDate(new Date().getDate()))) +
        " 00:00:00"
    );
    this.user = this.authService.getDecodeToken();
    this.searchForm = this.fb.group({
      startDate: this.startValue,
      endDate: this.endValue,
    });
    this.getDataHistory(this.startValue, this.endValue);
  }
  getDataHistory(startDate: any, endDate: any) {
    this.loading = true;
    const start_date = (new Date(startDate).getTime() / 1000).toString();
    const end_date = (new Date(endDate).getTime() / 1000).toString();
    console.log(new Date(startDate).getTime());
    this.playersService
      .affiliatePlayCommission(
        this.user.preferred_username,
        start_date,
        end_date
      )
      .subscribe((resp: any) => {
        if (resp) {
          this.dataSource = [
            {
              no: "",
              text: "รวม",
              amount: resp.data[0].turnover,
              commission: resp.data[0].commission,
            },
          ];
        }
      });
    this.loading = false;
  }
  // ------------------------------------ date ------------------------------------
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };
  onStartChange(date: Date): void {
    this.startValue = this.utilService.truncateTime(date);
  }
  onEndChange(date: Date): void {
    this.endValue = this.utilService.truncateTime(date);
  }
  handleStartOpenChange(open: boolean): void {}

  handleEndOpenChange(open: boolean): void {}
}
