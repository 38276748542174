<form nz-form [formGroup]="validateForm" autocomplete="off" class="inputForm">
  <div>
    <div class="mb-10">
      <nz-form-label><span class="text-label">ธนาคาร</span></nz-form-label>
      <div [ngClass]="this.selected === '' ? '-err' : ''">
        <div class="bank-grid">
          <div
            class="bank-box"
            *ngFor="let banks of bankSelect"
            [ngClass]="{ active: selected == banks.code }"
            (click)="handleBankSelect(banks.code)"
          >
            <i [class]="'xxxxl bank bank-' + banks.code.toLowerCase()"></i>
            <label class="text-label">{{ banks.name }}</label>
          </div>
        </div>
        <p *ngIf="this.selected === ''">กรุณาระบุธนาคาร</p>
      </div>
    </div>
    <div class="mb-10">
      <div class="text-label z-30"><span>{{ selected !== 'TRUE' ? 'เลขบัญชีธนาคาร' : 'เบอร์โทรศัพท์' }}</span></div>
      <nz-form-control>
        <div
          [ngClass]="isControlHasError('account_no', 'required') ? '-err' : ''"
        >
          <input
            id="account_no"
            type="text"
            nz-input
            formControlName="account_no"
            [maxlength]="account_no_maxlength"
            (input)="numberchecked($event)"
          />
          <p *ngIf="isControlHasError('account_no', 'required')">กรุณาระบุ</p>
        </div>
      </nz-form-control>
    </div>
    <div class="mb-10">
      <div class="text-label z-30"><span>ชื่อบัญชี</span></div>
      <nz-form-control>
        <div
          [ngClass]="
            isControlHasError('account_name', 'required') ? '-err' : ''
          "
        >
          <input type="text" nz-input formControlName="account_name" />
          <p *ngIf="isControlHasError('account_name', 'required')">กรุณาระบุ</p>
        </div>
      </nz-form-control>
    </div>
  </div>
</form>
<div class="center">
  <div class="btn-submit-2 btn-third w-full mt-30" (click)="submitForm()">
    ยืนยัน
  </div>
</div>
