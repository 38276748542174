import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { AuthService } from "src/app/_service/auth.service";
import { MockDataService } from "src/app/_service/mock-data.service";
import { environment } from "src/environments/environment";
import { PlayersService } from "src/app/_service/players.service";

@Component({
  selector: "app-affiliate",
  templateUrl: "./affiliate.component.html",
  styleUrls: ["./affiliate.component.scss"],
})
export class AffiliateComponent implements OnInit {
  imgError = "assets/image/mock_promo.png";
  isLogin: any;
  user: any = {
    nameid: "",
  };
  balance = 0;
  value = "";
  linkInvite = "";
  downline = [];
  transactions = [];
  isLoading = false;
  isOkLoading = false;
  isVisibleConfirmDialog = false;
  elementType: any = "url";
  constructor(
    private router: Router,
    private authService: AuthService,
    private message: NzMessageService,
    private mockDataService: MockDataService,
    private playersService: PlayersService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.user = this.authService.getDecodeToken();
    this.isLogin = this.authService.loggedIn();
    if (this.isLogin) {
      this.getData();
      // this.getBalance();
      // this.genUrl();
    }
  }

  getData() {
    this.playersService.getAffiliateData().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.balance;
        this.value = resp.code;
        this.linkInvite = environment.webUrl + "/register/" + resp.code;
        this.downline = resp.down_line;
        this.transactions = resp.transactions;
      }
    });
  }

  genUrl() {
    this.mockDataService
      .genLinkRef(this.user)
      .toPromise()
      .then((resp: any) => {
        if (resp) {
          this.value = resp.urlRef;
          this.linkInvite = resp.linkInvite;
        }
      });
  }
  getBalance() {
    this.balance = 0;
    this.message.success("อัปเดตกระเป๋ารายได้");
    this.playersService.getAffiliateBalance().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.balance;
      }
    });
  }
  comfirm() {
    if (this.balance < 300) {
      this.message.error("ยอดถอนขั้นตํ่า 300");
    } else {
      this.isLoading = true;
      this.isVisibleConfirmDialog = true;
    }
  }

  handleSubmit() {
    this.playersService.affiliateTrensfer().subscribe((resp: any) => {
      if (resp) {
        this.message.success("ทำรายการสำเร็จ");
        this.getBalance();
      }
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }
  handleCancel() {
    this.isVisibleConfirmDialog = false;
    this.isLoading = false;
  }
  goto(action: any) {
    this.router.navigateByUrl("/" + action);
  }
}
