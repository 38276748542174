<form nz-form [formGroup]="validateForm" autocomplete="off" class="inputForm">
  <div>
    <div class="mt-20 mb-10">
      <div class="text-label"><span>รหัสผู้แนะนำ (ถ้ามี)</span></div>
      <nz-form-control>
        <input
          type="text"
          nz-input
          formControlName="seller_code"
          placeholder="ผู้ชักชวน"
        />
      </nz-form-control>
    </div>
    <div
      class="mb-10"
      *ngIf="
        validateForm.value.seller_code == '' ||
        validateForm.value.seller != 'notSpecified'
      "
    >
      <div class="text-label"><span>รู้จักเราจากที่ไหน</span></div>
      <nz-form-control>
        <div [ngClass]="isControlHasError('seller', 'required') ? '-err' : ''">
          <nz-select
            formControlName="seller"
            name="seller"
            (ngModelChange)="sellerchange()"
          >
            <nz-option
              *ngFor="let item of sellerOptions"
              [nzLabel]="item.label"
              [nzValue]="item.value"
            ></nz-option>
          </nz-select>
        </div>
      </nz-form-control>
    </div>
    <div class="mb-10">
      <div class="text-label"><span>เบอร์โทรศัพท์</span></div>
      <nz-form-control>
        <div [ngClass]="isControlHasError('tel', 'required') ? '-err' : ''">
          <input
            type="tel"
            nz-input
            formControlName="tel"
            mask="0000000000"
            placeholder="กรอกหมายเลขโทรศัพท์"
            autofocus
          />
          <p *ngIf="isControlHasError('tel', 'required')">กรุณาระบุ</p>
        </div>
      </nz-form-control>
    </div>
    <div class="mb-10">
      <div class="text-label"><span>รหัสผ่าน</span></div>
      <nz-form-control>
        <div
          [ngClass]="
            isControlHasError('password', 'required') ||
            isControlHasError('password', 'minlength') ||
            isControlHasError('password', 'required')
              ? '-err'
              : ''
          "
        >
          <nz-input-group [nzSuffix]="suffixTemplate" class="custom-password">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              nz-input
              minlength="6"
              type="password"
              formControlName="password"
              (ngModelChange)="updateConfirmValidator()"
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span
              nz-icon
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></span>
          </ng-template>
          <p *ngIf="isControlHasError('password', 'required')">กรุณาระบุ</p>
          <p *ngIf="isControlHasError('password', 'minlength')">
            ระบุรหัสผ่านอย่างน้อย 6 หลัก
          </p>
        </div>
      </nz-form-control>
    </div>
    <div class="mb-10">
      <div class="text-label z-30"><span>ยืนยันรหัสผ่าน</span></div>
      <nz-form-control>
        <div
          [ngClass]="
            isControlHasError('confirm_password', 'required') ||
            isControlHasError('confirm_password', 'minlength') ||
            isControlHasError('confirm_password', 'confirm')
              ? '-err'
              : ''
          "
        >
          <nz-input-group
            [nzSuffix]="suffix2Template"
            class="custom-password z-30"
          >
            <input
              [type]="cfPasswordVisible ? 'text' : 'password'"
              nz-input
              minlength="6"
              type="password"
              autocomplete="new-password"
              formControlName="confirm_password"
            />
          </nz-input-group>
          <ng-template #suffix2Template>
            <span
              nz-icon
              [nzType]="cfPasswordVisible ? 'eye-invisible' : 'eye'"
              (click)="cfPasswordVisible = !cfPasswordVisible"
            ></span>
          </ng-template>
          <p *ngIf="isControlHasError('confirm_password', 'required')">
            กรุณาระบุ
          </p>
          <p *ngIf="isControlHasError('confirm_password', 'confirm')">
            รหัสผ่านไม่ตรงกัน
          </p>
          <p *ngIf="isControlHasError('confirm_password', 'minlength')">
            ระบุรหัสผ่านอย่างน้อย 6 หลัก
          </p>
        </div>
      </nz-form-control>
    </div>
  </div>
</form>
<div class="center">
  <div class="btn-submit btn-secondary mt-30 z-30" (click)="submitForm()">
    ยืนยัน
  </div>
  <!-- <button
    nz-button
    class="btn-gold z-30"
    (click)="submitForm()"
    [nzLoading]="this.loading"
  >
    ยืนยัน
  </button> -->
</div>
