<!--  -->
<app-header></app-header>
<app-side-menu />
<div class="bg-main" [ngClass]="{ '-isLogin': isLogin }">
  <div class="headerline"></div>
  <div class="banner-section border-after">
    <app-carousel classname="noProfile"></app-carousel>
  </div>
  <app-header-menu></app-header-menu>
  <div class="zIndex container">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>
