<!-- <header class="header" [ngClass]="{ 'border-after': isLogin }"> -->
<header class="header border-after">
  <div class="header-panel" [ngClass]="{ noLogin: !isLogin }">
    <div class="header-img">
      <img src="assets/image/piramid.png" alt="" />
    </div>
    <img
      src="assets/image/logo.png"
      alt=""
      class="logo z-10"
      (click)="goto('')"
    />
    <!--  -->
    <div class="header-right" *ngIf="isLogin">
      <div class="profile z-10">
        <div class="profile-detail m-10">
          <p (click)="handleModalProfile()">
            {{ this.user.preferred_username }}
          </p>
          <p
            class="-cash"
            nz-tooltip
            nzTooltipPlacement="top"
            nzTooltipTitle="อัปเดตยอดเงิน"
            (click)="this.getBalance(true)"
          >
            <img src="assets/image/i-refresh.svg" alt="" class="i-refresh" />
            {{ this.balance | currency : "" : "" }} {{ this.currency }}
          </p>
        </div>
        <img
          src="assets/image/profile.jpg"
          alt=""
          class="i-profile"
          (click)="handleModalProfile()"
        />
        <a class="-logout" (click)="logout()">ออกจากระบบ</a>
        <!-- <img
          src="assets/image/menu.png"
          (click)="showNav()"
          class="i-menu"
          alt=""
        /> -->
      </div>
      <div class="mobile-logout z-10">
        <a class="" (click)="logout()">ออกจากระบบ</a>
      </div>
      <div class="no-mobile">
        <button
          *ngIf="isLogin"
          class="btn-primary"
          (click)="handleModalHistory()"
        >
          ประวัติการเดิมพัน
        </button>
        <button
          *ngIf="isLogin"
          class="btn-secondary btn-with-img"
          (click)="handleModalDeposit()"
        >
          ฝากเงิน
        </button>
        <button
          *ngIf="isLogin"
          class="btn-secondary btn-with-img"
          (click)="handleModalWithdraw()"
        >
          ถอนเงิน
        </button>
      </div>
    </div>
    <div class="header-notlogin-right" *ngIf="!isLogin && !isHidden">
      <div
        class="btn-m-header btn-secondary mobile"
        (click)="handleModalRegister()"
      >
        <label>สมัครสมาชิก</label>
      </div> 
      <div
        class="btn-m-header2 btn-primary mobile"
        (click)="handleModalLogin()"
      >
        <label>เข้าสู่ระบบ</label>
      </div>
       <div
        class="btn-header btn-primary no-mobile"
        (click)="handleModalRegister()"
      >
        <label>สมัครสมาชิก</label>
      </div> 
      <div
        class="btn-header btn-primary no-mobile"
        (click)="handleModalLogin()"
      >
        <label>เข้าสู่ระบบ</label>
      </div>
      <a [href]="lineLink" target="_blank" class="no-mobile">
        <img src="assets/image/line.png" class="btn-line" alt="" />
      </a>
    </div>
  </div>
</header>
<app-nav-menu
  [isVisible]="this.isVisible"
  (handleNavMenu)="showNav()"
></app-nav-menu>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleProfile"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalProfile()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-profile [lineLink]="lineLink"></app-modal-profile>
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleLogin"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalLogin()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-form-login></app-form-login>
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleRegister"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalRegister()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-register></app-modal-register>
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [nzWidth]="370"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleHistory"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalHistory()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-history
        [isTitle]="true"
        [lineLink]="lineLink"
      ></app-modal-history>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleWithdraw"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalWithdraw()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-withdraw
        (handleCloseModal)="closeModalWithdraw($event)"
        [lineLink]="lineLink"
      ></app-modal-withdraw>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleDeposit"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalDeposit()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-deposit [lineLink]="lineLink"></app-modal-deposit>
    </div>
  </ng-container>
</nz-modal>
