import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/_service/auth.service";
import { ModalChangePasswordComponent } from "../modal/modal-change-password/modal-change-password.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent implements OnInit {
  @Output() public handleNavMenu = new EventEmitter();
  @Input() isVisible?: boolean;
  version = environment.appVersion;
  checkPath = "";
  isLogin = false;
  user: any = {};

  constructor(
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    this.checkPath = this.router.url;
  }

  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }

  showNav() {
    this.handleNavMenu.emit(!this.isVisible);
  }

  openDialogChangePassword(): void {
    this.user = this.authService.getDecodeToken();
    const modal: any = this.modal.create({
      nzTitle: "เปลี่ยนแปลงรหัสผ่าน",
      nzContent: ModalChangePasswordComponent,
      nzData: this.user,
      nzFooter: [
        {
          label: "ปิด",
          onClick: () => modal.destroy(),
        },
        {
          label: "ยืนยัน",
          type: "primary",
          loading: false,
          onClick(): void {
            const instance = modal.getContentComponent();
            for (const i in instance.validateForm.controls) {
              instance.validateForm.controls[i].markAsDirty();
              instance.validateForm.controls[i].updateValueAndValidity();
            }

            if (instance.validateForm.valid) {
              this.loading = true;
              this.label = "กรุณารอสักครู่...";
              instance.authService
                .changePassword(
                  instance.validateForm.controls["password"].value
                )
                .subscribe(
                  (resp: any) => {
                    modal.destroy();
                    instance.message.success("เปลี่ยนรหัสผ่าน สำเร็จ");
                    setTimeout(() => {
                      instance.authService.logout();
                    }, 1000);
                  },
                  (err: Error) => {
                    modal.destroy();
                  }
                );
            } else {
              // this.message.error(resp.message);
            }
          },
        },
      ],
    });
  }
}
