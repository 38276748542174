<!-- <app-header-menu
  (hamdleGameType)="hamdleGameType($event)"
  [checkType]="this.checkType"
></app-header-menu> -->

<div class="panel-gray">
  <div class="title">
    <img src="assets/image/header.png" alt="" />
    <span class="title-text">{{
      this.checkType == "LIVE"
        ? "คาสิโนสด"
        : this.checkType == "SLOT"
        ? "สล๊อตออนไลน์"
        : this.checkType == "SPORT"
        ? "กีฬาออนไลน์"
        : this.checkType == "LOTTO"
        ? "หวยออนไลน์"
        : this.checkType
    }}</span>
  </div>
  <div class="game-grid grid-5">
    <div class="game-box" *ngFor="let item of listGames as folder">
      <a class="game-item" (click)="gotoLaunchGame(item)">
        <div class="game-block">
          <img
            [src]="this.getImgProvider(item.game_provider_code)"
            src-fallback="{{ this.imgError }}"
            (loaded)="onLoaded($event)"
            alt=""
          />
          <div class="game-hover">
            <button class="btn-secondary">เข้าเล่น</button>
          </div>
        </div>
      </a>
      <div class="game-name">
        <span>{{ item.game_provider_name_en }}</span>
      </div>
    </div>
  </div>
</div>

<!-- ======================================================== -->
<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleLogin"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalLogin()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-form-login></app-form-login>
    </div>
  </ng-container>
</nz-modal>
