import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { AuthService } from "src/app/_service/auth.service";
import { PlayersService } from "src/app/_service/players.service";
import { CookieUtilService } from "src/app/_service/cookie-util.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() lineId!: string | null;
  @Input() lineLink!: string | null;
  user: any = {};
  isVisibleHistory = false;
  isVisibleWithdraw = false;
  isVisibleDeposit = false;
  isVisibleRegister = false;
  isVisibleProfile = false;
  isVisibleLogin = false;
  isVisible = false;
  isLogin = false;
  isHidden = false;
  checkPath = "";
  balance?: number;
  currency = "THB";

  constructor(
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService,
    private playersService: PlayersService,
    public cookieUtilService: CookieUtilService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    this.checkPath = this.router.url;
    if (this.checkPath.includes("/register")) {
      this.isVisibleRegister = true;
    }
    if (this.isLogin) {
      this.user = this.authService.getDecodeToken();
      this.getBalance(false);
    }
  }
  getBalance(isClick: boolean) {
    this.playersService.getBalance().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.Balance;
        if (isClick) this.message.success("อัปเดตกระเป๋าเงิน");
      }
    });
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  // --------------------------------
  showNav() {
    this.isVisible = !this.isVisible;
  }
  handleModalProfile() {
    this.isVisibleProfile = !this.isVisibleProfile;
  }
  handleModalRegister() {
    this.isVisibleRegister = !this.isVisibleRegister;
  }
  handleModalLogin(): void {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
  logout() {
    localStorage.clear();
    window.location.reload();
  }
  handleModalDeposit() {
    this.isVisibleDeposit = !this.isVisibleDeposit;
  }
  handleModalWithdraw() {
    this.isVisibleWithdraw = !this.isVisibleWithdraw;
  }
  handleModalHistory() {
    this.isVisibleHistory = !this.isVisibleHistory;
  }
  closeModalWithdraw(value: any) {
    this.isVisibleWithdraw = !value;
  }
}
