<div class="z-30">
  <ul class="tab-footer-nav">
    <li *ngIf="!isLogin">
      <a (click)="handleModalRegister()">
        <img src="assets/image/register-footer.png" alt="" />
      </a>
    </li>
    <li *ngIf="!isLogin">
      <a (click)="handleModalLogin()"
        ><img src="assets/image/login-footer.png" alt="" />
      </a>
    </li>
    <li *ngIf="isLogin">
      <a (click)="handleModalDeposit()">
        <div class="nav-btn">
          <img src="assets/image/nav/nav-m-deposit.png" alt="" />
          <span>ฝาก</span>
        </div>
      </a>
    </li>
    <li *ngIf="isLogin">
      <a (click)="handleModalWithdraw()">
        <div class="nav-btn">
          <img src="assets/image/nav/nav-m-withdraw.png" alt="" />
          <span>ถอน</span>
        </div>
      </a>
    </li>
    <li *ngIf="isLogin">
      <a (click)="goto('/promotion')">
        <div class="nav-btn">
          <img src="assets/image/nav/nav-m-promotion.png" alt="" />
          <span>โปรโมชั่น</span>
        </div>
      </a>
    </li>
    <li *ngIf="isLogin">
      <a (click)="goto('affiliate')">
        <div class="nav-btn">
          <img src="assets/image/nav/nav-m-affiliate.png" alt="" />
          <span>หารายได้</span>
        </div>
      </a>
    </li>
  </ul>
</div>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleLogin"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalLogin()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-form-login></app-form-login>
    </div>
  </ng-container>
</nz-modal>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleRegister"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalRegister()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-register></app-modal-register>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleWithdraw"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalWithdraw()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-withdraw
        (handleCloseModal)="closeModalWithdraw($event)"
      ></app-modal-withdraw>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-profile-modal"
  [(nzVisible)]="isVisibleDeposit"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalDeposit()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-deposit></app-modal-deposit>
    </div>
  </ng-container>
</nz-modal>
