import { NgModule } from "@angular/core";
import { AuthGuard } from "./_guard/auth.guard";
import { MainComponent } from "./modules/main/main.component";
import { LoginComponent } from "./modules/login/login.component";
import { PromotionComponent } from "./modules/promotion/promotion.component";
import { GameSlotIdComponent } from "./modules/game/game-slot-id/game-slot-id.component";
import { SlotComponent } from "./modules/game/slot/slot.component";
import { SportComponent } from "./modules/game/sport/sport.component";
import { CasinoComponent } from "./modules/game/casino/casino.component";
import { LottoComponent } from "./modules/game/lotto/lotto.component";
import { AffiliateComponent } from "./modules/affiliate/affiliate.component";
import { SiteMaintenanceComponent } from "./modules/site-maintenance/site-maintenance.component";
import { WheelComponent } from "./modules/wheel/wheel.component";
import { ReferralComponent } from "./modules/referral/referral.component";
import { LaunchGameComponent } from "./modules/launch-game/launch-game.component";
import { RateMuayComponent } from "./modules/muay/rate-muay/rate-muay.component";
import { MuayTodayComponent } from "./modules/muay/muay-today/muay-today.component";
import { LiveScoreComponent } from "./modules/muay/live-score/live-score.component";
import { MuayThaiComponent } from "./modules/muay/muay-thai/muay-thai.component";
import { LiveComponent } from "./modules/muay/live/live.component";
import { WebComponent } from "./modules/muay/web/web.component";
import { RegisterComponent } from "./modules/register/register.component";
import { FinancialComponent } from "./modules/financial/financial.component";
import { HistoryComponent } from "./modules/history/history.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", component: MainComponent, runGuardsAndResolvers: "always" },
  // { path: "login", component: LoginComponent, runGuardsAndResolvers: "always" },
  {
    path: "register",
    component: MainComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "register/:seller_code",
    component: MainComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "promotion",
    component: PromotionComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "slot",
    component: SlotComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "game/:param",
    component: GameSlotIdComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "sport",
    component: SportComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "casino",
    component: CasinoComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "lotto",
    component: LottoComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "affiliate",
    component: AffiliateComponent,
    runGuardsAndResolvers: "always",
  },
  { path: "maintenance", component: SiteMaintenanceComponent },
  { path: "spin", component: WheelComponent, runGuardsAndResolvers: "always" },
  {
    path: "referral/:code",
    component: ReferralComponent,
    runGuardsAndResolvers: "always",
  },

  {
    path: "เรตมวย",
    component: RateMuayComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "มวยวันนี้",
    component: MuayTodayComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "ผลมวยล่าสุด",
    component: LiveScoreComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "มวยไทย",
    component: MuayThaiComponent,
    runGuardsAndResolvers: "always",
  },
  {
    path: "ดูมวยสด",
    component: LiveComponent,
    runGuardsAndResolvers: "always",
  },
  { path: "เว็บมวย", component: WebComponent, runGuardsAndResolvers: "always" },
  {
    path: "",
    runGuardsAndResolvers: "always",
    canActivate: [AuthGuard],
    children: [
      {
        path: "history",
        component: HistoryComponent,
        runGuardsAndResolvers: "always",
      },
      {
        path: "deposit",
        component: FinancialComponent,
        runGuardsAndResolvers: "always",
      },
      {
        path: "withdraw",
        component: FinancialComponent,
        runGuardsAndResolvers: "always",
      },
      {
        path: "launch/:id",
        component: LaunchGameComponent,
        runGuardsAndResolvers: "always",
      },
    ],
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
