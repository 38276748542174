<div class="container minH80 py-20">
  <div class="maxW-400">
    <div class="title">
      <img src="assets/image/header.png" alt="" />
      <span class="title-text">สมัครสมาชิก</span>
    </div>
    <nz-steps
      nzType="navigation"
      [nzCurrent]="current"
      [nzProgressDot]="progressTemplate"
      class="custom-step"
    >
      <nz-step nzTitle="ข้อมูลส่วนตัว"></nz-step>
      <nz-step nzTitle="ข้อมูลบัญชี"></nz-step>
    </nz-steps>
  </div>
  <div class="panel-step step1" *ngIf="this.current == 0">
    <app-step1 (handleStep)="stepNext($event)"></app-step1>
  </div>
  <!-- -------------------------------------------------- -->
  <div class="panel-step step2" *ngIf="this.current == 1">
    <app-step2
      (handleStep)="stepNext($event)"
      [dataRef]="this.dataRef"
    ></app-step2>
  </div>

  <ng-template #progressTemplate let-dot let-status="status" let-index="index">
    <span style="margin-left: -100%">
      <ng-template [ngTemplateOutlet]="dot"></ng-template>
    </span>
  </ng-template>
</div>
<div class="mt-50"></div>
<div class="bg-footer flex justify-center">
  <span class="tx-white"
    >พบปัญหา
    <a class="text-yellow underline">ติดต่อฝ่ายบริการลูกค้า</a>
  </span>
</div>
