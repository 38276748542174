import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MockDataService } from 'src/app/_service/mock-data.service';
import { AuthService } from 'src/app/_service/auth.service';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PlayersService } from 'src/app/_service/players.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  lineID = environment.lineID;
  lineLink = environment.lineLink;
  listBanner: any[] = [];
  isLogin = false;
  isVisibleHistory = false;
  isVisibleWithdraw = false;
  isVisibleDeposit = false;
  currency = "THB";
  balance?: number;
  chip = '0.00';
  amount = '0.00';
  money = '0.00';
  user: any = {};

  constructor(
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService,
    private playersService: PlayersService,
    private message: NzMessageService,
    private mockDataService: MockDataService
  ) { }

  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    if (this.isLogin) {
      this.getBalance(false);
    }
    this.mockDataService.getBanner().subscribe((resp: any) => {
      this.listBanner = resp.data;
    })
  }
  //--------------------------------
  getBalance(isClick: boolean) {
    this.playersService.getBalance().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.Balance;
        if (isClick) this.message.success("อัปเดตกระเป๋าเงิน")
      }
    });
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  //--------------------------------
  handleModalDeposit() {
    this.isVisibleDeposit = !this.isVisibleDeposit;
  }
  handleModalWithdraw() {
    this.isVisibleWithdraw = !this.isVisibleWithdraw;
  }
  handleModalHistory() {
    this.isVisibleHistory = !this.isVisibleHistory;
  }
  closeModalWithdraw(value: any) {
    this.isVisibleWithdraw = !value;
  }
}
