import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  NgZone,
  Input,
} from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { PlayersService } from "src/app/_service/players.service";
import { UtilService } from "src/app/_service/util.service";
import { WithdrawStatus } from "src/app/_model/user";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-modal-withdraw",
  templateUrl: "./modal-withdraw.component.html",
  styleUrls: ["./modal-withdraw.component.scss"],
})
export class ModalWithdrawComponent implements OnInit {
  @Output() public handleCloseModal = new EventEmitter();
  @Input() lineLink!: string | null;
  minValue = 0;
  withdrawAmount = 0;
  maxValue = 200000;
  listOfData: any[] = [];
  showWarning = false;
  withdrawStatus: WithdrawStatus = new WithdrawStatus();
  isDisabled = true;
  isWithdrawAll = false;
  massage = [];
  isPending = false;
  balance = 0;
  isLoading = false;

  constructor(
    public util: UtilService,
    private message: NzMessageService,
    public playersService: PlayersService,
    private ngZone: NgZone
  ) {
    this.getWithdrawStatus();
  }

  ngOnInit(): void {
    this.getWithdrawTxn();
    this.getBalance();
  }
  confirm() {
    this.isDisabled = true;
    if (
      this.withdrawAmount > 0 &&
      this.withdrawAmount >= this.minValue &&
      this.withdrawAmount <= this.maxValue &&
      this.withdrawAmount <= this.balance
    ) {
      this.playersService
        .withdrawPlayer(this.withdrawAmount)
        .subscribe((resp: any) => {
          this.handleCloseModal.emit(true);
          this.message.success("ทำรายการสำเร็จ");
          this.getWithdrawTxn();
        });
    } else {
      this.showWarning = true;
    }
    setTimeout(() => {
      this.isDisabled = false;
    }, 2000);
  }

  changeAmount() {
    if (this.withdrawStatus.status === "SUCCESS" && !this.isPending) {
      this.isDisabled = !(
        this.withdrawAmount > 0 &&
        this.withdrawAmount >= this.minValue &&
        this.withdrawAmount <= this.maxValue &&
        this.withdrawAmount <= this.balance
      );
    }
  }

  getWithdrawTxn() {
    this.isPending = false;
    this.playersService.getWithdrawTxn().subscribe((resp: any) => {
      if (resp.withdraws) {
        this.listOfData = resp.withdraws;
      }
      if (this.listOfData) {
        for (let item of this.listOfData) {
          if (item.status == "pending" || item.status == "initiate") {
            this.isPending = true;
          }
        }
      }
    });
  }
  getWithdrawStatus() {
    this.massage = [];
    this.isWithdrawAll = false;
    this.isLoading = true;
    this.playersService.getWithdrawStatus().subscribe(async (resp: any) => {
      this.withdrawStatus = resp as WithdrawStatus;
      if (this.withdrawStatus.status === "SUCCESS") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (this.withdrawStatus.message) {
        this.massage = this.withdrawStatus.message;
      }
      if (this.withdrawStatus.is_withdraw_all_credit) {
        await this.getBalanceAmount();
        this.isWithdrawAll = true;
      }
      if (this.withdrawStatus.max_withdraw_credit_amount) {
        this.maxValue = this.withdrawStatus.max_withdraw_credit_amount;
      }

      if (this.withdrawStatus.min_withdraw_credit_amount) {
        this.minValue = this.withdrawStatus.min_withdraw_credit_amount;
        if (
          this.minValue > 0 &&
          (this.withdrawAmount <= this.minValue ||
            this.withdrawAmount >= this.maxValue)
        ) {
          this.isDisabled = true;
        }
      }

      this.ngZone.runOutsideAngular(() => {
        // Your code here will be executed outside Angular's zone
        // This is similar to the behavior of Vue's nextTick
        this.ngZone.run(() => {
          // Your code here will be executed inside Angular's zone
          this.isLoading = false;
        });
      });
    });
  }

  async getBalanceAmount() {
    const response$ = this.playersService.getBalance();
    const response = await lastValueFrom(response$);
    if (response) {
      this.withdrawAmount = response.Balance;
    }
  }
  getBalance() {
    this.playersService.getBalance().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.Balance;
      }
    });
  }

  parser(value: any) {
    return value!.replace(/\$\s?|(,*)/g, "");
  }
  formatter(value: any) {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
