<!--  -->
<div class="banner-section">
  <app-carousel></app-carousel>
</div>
<!-- ===================================================================================================  -->
<nz-modal
  [nzWidth]="370"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleHistory"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalHistory()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-history [isTitle]="true"></app-modal-history>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleWithdraw"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalWithdraw()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-withdraw
        (handleCloseModal)="closeModalWithdraw($event)"
      ></app-modal-withdraw>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [nzWidth]="380"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleDeposit"
  nzWrapClassName="modal-login modal-profile"
  (nzOnCancel)="handleModalDeposit()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-financial></app-modal-financial>
    </div>
  </ng-container>
</nz-modal>
