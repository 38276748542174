<div class="nav-menu">
  <div class="nav-row">
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/' }"
      (click)="goto('/')"
    >
      <label>หน้าหลัก</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/slot' }"
      (click)="goto('slot')"
    >
      <label>สล็อต</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/casino' }"
      (click)="goto('casino')"
    >
      <label>คาสิโน</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/sport' }"
      (click)="goto('sport')"
    >
      <label>กีฬา</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/lotto' }"
      (click)="goto('lotto')"
    >
      <label>หวย</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/promotion' }"
      (click)="goto('promotion')"
    >
      <label>โปรโมชั่น</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/spin' }"
      (click)="goto('spin')"
    >
      <label>วงล้อนำโชค</label>
    </div>
    <div
      class="btn-nev btn-secondary"
      [ngClass]="{ act: checkPath === '/affiliate' }"
      (click)="goto('affiliate')"
    >
      <label>หารายได้</label>
    </div>
    <div class="btn-nev2 btn-third" (click)="linelink()">
      <label>Pharaoh888</label>
    </div>
  </div>
</div>
