import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { ModalForgotPasswordComponent } from "../modal/modal-forgot-password/modal-forgot-password.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-form-login",
  templateUrl: "./form-login.component.html",
  styleUrls: ["./form-login.component.scss"],
})
export class FormLoginComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  loading = false;
  isVisibleForgotpassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private message: NzMessageService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }
  // ----------------------------------------------------
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  // ----------------------------------------------------
  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.submitForm();
    }
  }
  // ----------------------------------------------------
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const controls = this.validateForm.controls;
      this.loading = true;
      const authData = {
        username: controls["username"].value,
        password: controls["password"].value,
      };
      this.auth.login(authData.username, authData.password).subscribe({
        next: (resp: any) => {
          this.message.loading("กำลังเข้าสู่ระบบ...").onClose!.subscribe(() => {
            if (this.router.url === "/login") {
              this.router.navigateByUrl("/");
            } else {
              window.location.reload();
            }
          });
        },
        error: (e) => {
          console.log(e);
          this.loading = false;
        },
        complete: () => {},
      });
    }
  }
  // ---------------Modal---------------
  handleModalForgotpassword() {
    this.isVisibleForgotpassword = !this.isVisibleForgotpassword;
  }
  // --------------------------------
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.validateForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
