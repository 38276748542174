<!--  -->
<div class="panel-marquee">
  <div class="bor-announ">
    <img src="assets/image/announ.png" alt="" />
    <marquee scrollamount="5" direction="left">
      ยินดีต้อนรับเข้าสู่เว็บไซต์ Pharaoh888 ฝาก-ถอนออโต้ เกมส์สล๊อตออนไลน์
      อันดับหนึ่ง บริการด้านการเดิมพันครบวงจรตลอด 24 ชม.
    </marquee>
  </div>
</div>
