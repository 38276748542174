<!--  -->
<div class="login-body">
  <div class="w100p">
    <div class="head-login">
      <div class="title">
        <img src="assets/image/header.png" alt="" />
        <span class="title-text">เข้าสู่ระบบ</span>
      </div>
      <!-- <img src="assets/image/logo.png" alt=""> -->
    </div>
    <div class="login-panel">
      <form
        nz-form
        [formGroup]="validateForm"
        class="inputForm"
        nzLayout="vertical"
      >
        <nz-form-item>
          <nz-form-label>ชื่อผู้ใช้ หรือ เบอร์โทรศัพท์</nz-form-label>
          <nz-form-control nzErrorTip="กรุณาระบุ">
            <input type="text" nz-input formControlName="username" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>รหัสผ่าน</nz-form-label>
          <nz-form-control nzErrorTip="กรุณากรอก รหัสผ่าน">
            <input
              type="password"
              nz-input
              formControlName="password"
              placeholder="รหัสผ่าน"
            />
          </nz-form-control>
        </nz-form-item>
        <div class="btn-submit btn-secondary z-index" (click)="submitForm()">
          เข้าสู่ระบบ
        </div>
        <div class="flex justify-center mt-10">
          <div
            class="btn-submit-2 btn-third z-index"
            (click)="handleModalForgotpassword()"
          >
            ลืมรหัสผ่าน
          </div>
          <!-- <div class="flex justify-between m-10">
            <a class="tx-white a-hover" (click)="goto('register')"
              >สมัครสมาชิก</a
            >
            <a class="tx-white a-hover" (click)="handleModalForgotpassword()"
              >ลืมรหัสผ่าน</a
            >
          </div> -->
          <!-- <button class="btn-secondary m-10" (click)="handleModalForgotpassword()">ลืมรหัสผ่าน</button> -->
        </div>
      </form>
    </div>
  </div>
</div>
<div class="bg-footer flex justify-center">
  <span class="tx-white"
    >พบปัญหา
    <a class="text-yellow underline">ติดต่อฝ่ายบริการลูกค้า</a>
  </span>
</div>

<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleForgotpassword"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalForgotpassword()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-forgot-password (handleCloseModal)="handleModalForgotpassword()"></app-modal-forgot-password>
    </div>
  </ng-container>
</nz-modal>
