<!--  -->
<app-section-bg [template]="content"></app-section-bg>
<ng-template #content>
  <div class="container minH80 py-20">
    <div class="maxW-400">
      <h2 class="tx-white center">สมัครสมาชิก</h2>
      <nz-steps
        [nzCurrent]="current"
        [nzProgressDot]="progressTemplate"
        class="custom-step"
      >
        <nz-step nzTitle="ข้อมูลส่วนตัว"></nz-step>
        <nz-step nzTitle="ข้อมูลบัญชี"></nz-step>
      </nz-steps>
    </div>
    <div class="panel-step step1" *ngIf="this.current == 0">
      <app-step1 (handleStep)="stepNext($event)"></app-step1>
    </div>
    <!-- -------------------------------------------------- -->
    <div class="panel-step step2" *ngIf="this.current == 1">
      <app-step2
        (handleStep)="stepNext($event)"
        [dataRef]="this.dataRef"
      ></app-step2>
    </div>

    <ng-template
      #progressTemplate
      let-dot
      let-status="status"
      let-index="index"
    >
      <span style="margin-left: -100%">
        <ng-template [ngTemplateOutlet]="dot"></ng-template>
      </span>
    </ng-template>

    <div class="center mt-20">
      <a class="tx-white" (click)="goto('')">
        <span nz-icon nzType="left" nzTheme="outline"></span>
        กลับหน้าหลัก
      </a>
    </div>
  </div>
</ng-template>
