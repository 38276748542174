<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <app-banner></app-banner>
</ng-template>
<ng-template #content>
  <app-header-menu></app-header-menu>
  <div id="app">
    <div class="wheel__wrapper">
      <div class="wheel__controller" (click)="handleGetWheelDraw()">
        <h6 class="controller__label">{{ this.ticket }}</h6>
        <div class="spin__wrapper">
          <img src="assets/image/spin.png" alt="spin">
        </div>
      </div>
      <div class="content__wrapper" id="roulette">
        <div class="sector__wrapper">
          <div
            class="sector"
            *ngFor="let data of configurationWheel;"
            ></div>
        </div>
        <div class="label__wrapper">
          <div
            class="label"
            *ngFor="let data of configurationWheel;"
            >
            <img src="assets/image/coin.png" alt="Coin">
            <h2 class="text">{{ data.name }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="result-modal" class="result_modal__wrapper result_modal__hide">
    <div class="overlay"></div>
    <div class="modal">
      <h5>ยินดีด้วย! คุณได้รับ</h5>
      <h4>{{ configurationWheel[currentIndex]?.name || '' }}</h4>
      <button (click)="closeResultModal()" class="btn-nev btn-secondary">ปิด</button>
    </div>
  </div>
</ng-template>
