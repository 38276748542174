import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-financial',
  templateUrl: './modal-financial.component.html',
  styleUrls: ['./modal-financial.component.scss']
})
export class ModalFinancialComponent implements OnInit {
  @Input() isTitle: boolean = false;
  tabId = 0;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tabId = this.router.url === '/withdraw' ? 1 : 0;
  }
}
