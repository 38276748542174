import { Component } from "@angular/core";

@Component({
  selector: "app-lotto",
  templateUrl: "./lotto.component.html",
  styleUrls: ["./lotto.component.scss"],
})
export class LottoComponent {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
