<!--  -->
<div class="bg-dot">
    <img src="assets/image/bg.jpeg" alt="" class="img-bgDot">
    <div class="zIndex">
      <div class="content-detail">
          <img src="assets/image/screen.png" alt="" class="img-web">
          <p>
            {{ this.detail }}
          </p>
          <p>
            <span>{{ this.startDate }} | {{ this.startTime }}</span>
            ถึง
            <span>{{ this.endDate }} | {{ this.endTime }}</span>
          </p>
        </div>
    </div>
</div>

  