import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(public datepipe: DatePipe, private sanitizer: DomSanitizer) {}
  dateformat = "dd/MM/yyyy";

  displayImage(base64Image: string) {
    base64Image = "data:image/jpg;base64," + base64Image;
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }
  setToJsonDateTime(dateValue: any) {
    return this.datepipe.transform(dateValue, "yyyy-MM-ddTHH:mm:ss");
  }
  showToJsonDateTime(dateValue: any) {
    return this.datepipe.transform(dateValue, "dd-MM-yyyy HH:mm:ss");
  }
  setToJsonDateOnly(dateValue: any) {
    return this.datepipe.transform(dateValue, "yyyy-MM-dd");
  }
  truncateTime(date: Date) {
    if (date !== null) {
      date.setHours(0, 0, 0, 0);
    }
    return date;
  }
  compareDate(dateType: String, startDate: Date, endDate: Date) {
    if (!startDate || !endDate) {
      return false;
    }
    if (dateType === "startValue") {
      return startDate > endDate;
    } else {
      return endDate < startDate;
    }
  }
  colorOfNumber(data: any) {
    return data && Number(data) && Number(data) > 0 ? "tx-green" : "";
  }
  colorOfStatus(data: any) {
    switch (data) {
      case "success":
        return "tx-green";
      case "fail":
        return "tx-red";
      case "pending":
        return "tx-yellow";
      case "initiate":
        return "tx-yellow";
      case "reject":
        return "tx-red";
      default:
        return "";
    }
  }
  textTranslate(data: any) {
    switch (data) {
      case "success":
        return "สำเร็จ";
      case "fail":
        return "ไม่สำเร็จ";
      case "pending":
        return "รอดำเนินการ";
      case "initiate":
        return "ทำรายการ";
      case "approve":
        return "อนุมัติ";
      case "reject":
        return "ยกเลิก";
      default:
        return "";
    }
  }
}
