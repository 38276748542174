import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { UserRegister } from "../_model/user";
import { IPlayerResetPassword, IPlayerVerifyOtp } from "../_model/player";

@Injectable({
  providedIn: "root",
})
export class PlayersService {
  baseUrl = environment.apiUrl + environment.playersApi;
  token = localStorage.getItem("token");
  constructor(private http: HttpClient) {}

  getBalance(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/balance`, { headers: httpHeaders });
  }

  agentDepositAccount(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/agentDepositAccount`, {
      headers: httpHeaders,
    });
  }

  getDepositTxn(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/getDepositTxn`, {
      headers: httpHeaders,
    });
  }

  getWithdrawTxn(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/getWithdrawTxn`, {
      headers: httpHeaders,
    });
  }

  getWithdrawStatus(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/withdraw/status`, {
      headers: httpHeaders,
    });
  }

  registerPlayer(params: UserRegister): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.post(
      this.baseUrl + "/registerPlayer",
      {
        username: params.username,
        password: params.password,
        tel: params.tel,
        code: params.code,
        credit: params.credit,
        status: params.status,
        is_validate_bank_account: params.is_validate_bank_account,
        bank_accounts: params.bank_accounts,
        register_revenue_configuration: params.register_revenue_configuration,
        seller_code: params.seller_code,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  withdrawPlayer(withdrawAmount: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.post(
      this.baseUrl + "/withdraw",
      {
        withdrawAmount: withdrawAmount,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  uploadSlip(file: File, bank: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + this.token,
      "api-key": environment.apiKey,
    });
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("to_bank_code", bank.bank_name);
    formData.append("to_bank_acc_no_number", bank.account_no);
    formData.append("to_bank_acc_no_name", bank.account_name);
    return this.http.post(this.baseUrl + "/v1/deposit", formData, {
      headers: httpHeaders,
    });
  }

  uploadSlip3(file: File, bank: any): void {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    // return this.http
    //   .post(
    //     this.baseUrl + "/v1/deposit",
    //     {
    //       formData,
    //     },
    //     {
    //       headers: httpHeaders,
    //     }
    //   )
    this.http
      .post<any>(this.baseUrl + "/v1/deposit", formData, {
        headers: httpHeaders,
      })
      .subscribe(
        (response) => {
          console.log("File uploaded successfully:", response);
        },
        (error) => {
          console.error("File upload failed:", error);
        }
      );
    //
    // this.http.post<any>(this.baseUrl, formData, { headers }).subscribe();
    //
  }

  playerAcceptPromotion(id: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer " + this.token,
      "api-key": environment.apiKey,
    });
    const formData: FormData = new FormData();
    return this.http.put(this.baseUrl + `/promotion/` + id, formData, {
      headers: httpHeaders,
    });
  }

  getPlayerBankAccount(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/bankaccount`, {
      headers: httpHeaders,
    });
  }

  getBankOptions(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(environment.apiUrl + `/wallets/v1/banks/player/` + environment.agentCode, {
      headers: httpHeaders,
    });
  }

  getSellerList(agent: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/sellers/` + agent, {
      headers: httpHeaders,
    });
  }

  getAffiliateData(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/affiliate`, {
      headers: httpHeaders,
    });
  }

  getAffiliateBalance(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/affiliate/balance`, {
      headers: httpHeaders,
    });
  }

  affiliateTrensfer(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.post(this.baseUrl + `/v1/affiliate/transfer`, "", {
      headers: httpHeaders,
    });
  }

  affiliatePlayCommission(
    username: string,
    date_start: string,
    date_end: string
  ): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(
      environment.apiUrl +
        `/reports/v1/affiliate?username=` +
        username +
        "&date_start=" +
        date_start +
        "&date_end=" +
        date_end +
        "&order_by_field=turnover&order_by=-1&page=1&page_size=100",
      {
        headers: httpHeaders,
      }
    );
  }

  getLineInfo(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      // Authorization: "Bearer" + this.token,
      "api-key": environment.apiKey,
    });
    return this.http.get(
      environment.apiUrl +
        `/configuration/v1/client/notification/` +
        environment.agentCode,
      {
        headers: httpHeaders,
      }
    );
  }

  playerRequestOtp(phone: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.post(
      `${this.baseUrl}/v1/otp`,
      {
        agent_code: environment.agentCode,
        player_tel: phone
      },
      { headers: httpHeaders }
    );
  }

  playerVerifyOtp(body: IPlayerVerifyOtp): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.post(
      `${this.baseUrl}/v1/otp/verify`,
      {
        ...body,
        agent_code: environment.agentCode,
      },
      { headers: httpHeaders }
    );
  }

  playerResetPassword(body: IPlayerResetPassword): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.patch(
      `${this.baseUrl}/v1/reset/password`,
      {
        ...body,
        agent_code: environment.agentCode,
      },
      { headers: httpHeaders }
    );
  }
}
