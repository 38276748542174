<nz-table
  #basicTable
  class="custom-table"
  [nzData]="transactions"
  [nzScroll]="{ x: '330px' }"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  <thead>
    <tr>
      <th nzWidth="70px">#</th>
      <th>วันสร้าง</th>
      <th>จำนวนเงิน</th>
      <th>สถานะ</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data; let k = index">
      <td>{{ k + 1 }}</td>
      <td>{{ data.created_date | date : "dd/MM/yyyy HH:mm" }}</td>
      <td>{{ data.amount | currency : "" : "" }}</td>
      <td [ngClass]="this.util.colorOfStatus(data.status)">
        {{ this.util.textTranslate(data.status) }}
      </td>
    </tr>
  </tbody>
</nz-table>
