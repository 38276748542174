<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <app-banner></app-banner>
  <app-header-menu></app-header-menu>
</ng-template>
<ng-template #content>
  <div class="minH50">
    <div class="container">
      <app-game-list checkType="SPORT"></app-game-list>
    </div>
  </div>
</ng-template>
<!-- ======================================================== -->
