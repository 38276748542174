import { Component } from '@angular/core';

@Component({
  selector: 'app-muay-today',
  templateUrl: './muay-today.component.html',
  styleUrls: ['./muay-today.component.scss']
})
export class MuayTodayComponent {

}
