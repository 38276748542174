<div class="content-profile">
  <div class="title">
    <img src="assets/image/header.png" alt="" />
    <span class="title-text">ประวัติการเดิมพัน</span>
  </div>
  <div class="">
    <div class="flex justify-center">
      <div class="btn-winlose btn-secondary">
        <label>ผลแพ้ชนะ</label>
      </div>
    </div>

    <nz-table
      #ajaxTable
      nzShowSizeChanger
      class="custom-table"
      [nzData]="dataWinloss"
      [nzLoading]="loading"
      [nzScroll]="{ x: '350px' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
    >
      <thead>
        <tr>
          <th nzWidth="50px">
            <span *ngIf="!isList2 && !isList3">#</span>
            <button
              class="btn-back"
              *ngIf="isList2 && !isList3"
              (click)="closeList2()"
            >
              <span nz-icon nzType="left" nzTheme="outline"></span>
            </button>
            <button class="btn-back" *ngIf="isList3" (click)="closeList3()">
              <span nz-icon nzType="left" nzTheme="outline"></span>
            </button>
          </th>
          <th nzWidth="100px">
            {{
              isList2 && !isList3 ? "เกม" : isList3 ? "รายละเอียด" : "วันที่"
            }}
          </th>
          <th nzWidth="45px">แทง</th>
          <th nzWidth="65px">คอมมิชชั่น</th>
          <th nzWidth="55px">ได้/เสีย</th>
          <th nzWidth="55px" *ngIf="!isList3">รวม</th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <ng-template
            [ngIf]="!isList2"
            *ngFor="let data of ajaxTable.data; let i = index"
          >
            <tr>
              <td>{{ i + 1 }}</td>
              <td>
                <a
                  class="tx-yellow underline"
                  (click)="getHistoryByDate(data.date)"
                >
                  {{ data.date }}
                </a>
              </td>
              <td>{{ data.bets }}</td>
              <td>{{ data.commission }}</td>
              <td [ngClass]="this.util.colorOfNumber(data.ggr)">
                {{ data.ggr | currency : "" : "" }}
              </td>
              <td [ngClass]="this.util.colorOfNumber(data.ggr)">
                {{ data.ggr | currency : "" : "" }}
              </td>
            </tr>
          </ng-template>
          <ng-template [ngIf]="isList2 && !isList3">
            <tr *ngFor="let item of listSubOfData; let j = index">
              <td>{{ j + 1 }}</td>
              <td>
                <a
                  class="tx-yellow underline"
                  (click)="getHistoryByGame(item.game)"
                >
                  {{ item.game }}
                </a>
              </td>
              <td>{{ item.bets }}</td>
              <td>{{ item.commission }}</td>
              <td [ngClass]="this.util.colorOfNumber(item.ggr)">
                {{ item.ggr | currency : "" : "" }}
              </td>
              <td [ngClass]="this.util.colorOfNumber(item.ggr)">
                {{ item.ggr | currency : "" : "" }}
              </td>
            </tr>
          </ng-template>
          <ng-template [ngIf]="isList3">
            <tr *ngFor="let item of listSub2OfData; let k = index">
              <td>{{ k + 1 }}</td>
              <td>
                {{ item.game_type }}-{{ item.game_provider }}
                {{ item.transaction_time | date : "dd/MM/yyyy HH:mm" }}
              </td>
              <td>{{ item.bets }}</td>
              <td>{{ item.commission }}</td>
              <td [ngClass]="this.util.colorOfNumber(item.ggr)">
                {{ item.ggr | currency : "" : "" }}
              </td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
  <div class="mt-30">
    <div class="foot">
      <div>
        <span class="tx-white"
          >พบปัญหา
          <a
            [href]="lineLink"
            target="_blank"
            class="tx-yellow underline display-block fix-block"
            >ติดต่อฝ่ายบริการลูกค้า</a
          >
        </span>
      </div>
    </div>
  </div>
</div>
