import { Component, Input } from "@angular/core";
import { AuthService } from "src/app/_service/auth.service";
import { MockDataService } from "src/app/_service/mock-data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent {
  isVisibleRegister = false;
  @Input() classname: string = "";
  listBanner: any[] = [
    {
      image: "../../../assets/image/banner_register.png",
      text: "โปรแรงเว่อ กิจกรรมเพียบ",
      text2: "จัดหนัก แรงสุดในไทย",
      link: "register",
      //image_button: "../../../assets/image/register_button.png",
    },
  ];
  isLogin = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private mockDataService: MockDataService
  ) {}

  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    // this.mockDataService.getBanner().subscribe((resp: any) => {
    //   this.listBanner = resp.data;
    // })
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  handleModalRegister() {
    this.isVisibleRegister = !this.isVisibleRegister;
  }
}
