import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PromotionsService {
  baseUrl = environment.apiUrl + environment.promotionsApi;
  token = localStorage.getItem("token");
  constructor(private http: HttpClient) {}

  getPromotions(agent_code: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/public?agent_code=${agent_code}`, {
      headers: httpHeaders,
    });
  }

  getPromotionsByPlayer(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
      Authorization: "Bearer " + this.token,
    });
    return this.http.get(this.baseUrl + `/players`, {
      headers: httpHeaders,
    });
  }

  getPromotionImage(promotion_id: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/public/${promotion_id}/images`, {
      headers: httpHeaders,
      responseType: 'blob'
    });
  }
}
