import { Component } from "@angular/core";
@Component({
  selector: "app-sport",
  templateUrl: "./sport.component.html",
  styleUrls: ["./sport.component.scss"],
})
export class SportComponent {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
