<!--  -->
<div class="flex-center">
  <div
    class="banner-panel"
    [ngClass]="{ '-noLogin': !isLogin }"
    [class]="classname"
  >
    <nz-carousel nzAutoPlay [nzEnableSwipe]="false">
      <div nz-carousel-content *ngFor="let item of listBanner">
        <div class="box-carousel">
          <div class="banner-box-img">
            <img [src]="item.image" alt="" />

            <p>{{ item.text }}</p>
            <span>{{ item.text2 }}</span>
          </div>
        </div>
        <div class="headerline mobile"></div>
      </div>
    </nz-carousel>
  </div>
</div>
<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleRegister"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalRegister()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-modal-register></app-modal-register>
    </div>
  </ng-container>
</nz-modal>
