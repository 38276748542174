import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GamesService {
  baseUrl = environment.apiUrl + environment.gamesApi;
  agentCode = environment.agentCode;

  constructor(private http: HttpClient) {}
  launchGame(id: number, user_id: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      this.baseUrl + "/v1/launches",
      {
        game_id: id,
        user_id: user_id,
        agent_code: this.agentCode,
      },
      {
        headers: httpHeaders,
      }
    );
  }
  getAllGame(): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/games`);
  }
  getAllProviders(): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/providers`);
  }
  getGameByProvider(provider: string): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/games/` + provider);
  }
  getGameByType(type: string): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + `/v1/games/types/` + type);
  }

  getGameType(): Observable<any> {
    return this.http.get(this.baseUrl + `/v1/games/types`);
  }

  getProviderByGameType(id: number): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.get(
      this.baseUrl +
        `/v1/type/providers?game_type_id=` +
        id +
        `&agent_code=` +
        this.agentCode
    );
  }
}
