<!--  -->
<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent></ng-template>
<ng-template #content>
  <div class="container minH80">
    <div class="panel-form">
      <app-form-login></app-form-login>
    </div>
    <div class="center">
      <a class="tx-white" (click)="goto('')">
        <span nz-icon nzType="left" nzTheme="outline"></span>
        กลับหน้าหลัก</a
      >
    </div>
  </div>
</ng-template>
