import { Component } from "@angular/core";

@Component({
  selector: "app-slot",
  templateUrl: "./slot.component.html",
  styleUrls: ["./slot.component.scss"],
})
export class SlotComponent {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
