<div class="game-grid">
  <div class="game-box" *ngFor="let item of listGames as folder">
    <a class="game-item" (click)="gotoLaunchGame(item)">
      <div class="game-block">
        <img
          [src]="item.game_image_url"
          src-fallback="{{ this.imgError }}"
          (loaded)="onLoaded($event)"
          alt=""
        />
        <div class="game-hover">
          <button class="btn-secondary">เข้าเล่น</button>
        </div>
      </div>
    </a>
    <div class="game-name">
      <span>{{ item.game_name_en }}</span>
    </div>
  </div>
</div>

<!-- ======================================================== -->
<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleLogin"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalLogin()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-form-login></app-form-login>
    </div>
  </ng-container>
</nz-modal>
