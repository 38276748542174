import { Component, OnInit, Input } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { ModalChangePasswordComponent } from "../modal-change-password/modal-change-password.component";
import { AuthService } from "src/app/_service/auth.service";
import { PlayersService } from "src/app/_service/players.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-profile",
  templateUrl: "./modal-profile.component.html",
  styleUrls: ["./modal-profile.component.scss"],
})
export class ModalProfileComponent implements OnInit {
  @Input() lineLink!: string | null;
  user: any = {};
  version = "";
  bankCode = "";
  phoneNo = "-";
  balance?: number;
  currency = "THB";
  bank_data: any = {};

  constructor(
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService,
    private playersService: PlayersService
  ) {
    this.version = environment.appVersion;
  }

  ngOnInit(): void {
    this.user = this.authService.getDecodeToken();
    this.getBank();
    this.getBalance();
  }
  getBank(): void {
    this.playersService.getPlayerBankAccount().subscribe((resp) => {
      this.bank_data = resp.bank_accounts;
    });
  }
  //--------------------------------
  getBalance() {
    this.playersService.getBalance().subscribe((resp: any) => {
      if (resp) {
        this.balance = resp.Balance;
      }
    });
  }
  bankCodeIcon() {
    if (this.bank_data) {
      return this.bank_data.bank_name
        ? this.bank_data.bank_name.toLowerCase()
        : "";
    } else {
      return "";
    }
  }
  logout() {
    localStorage.clear();
    window.location.reload();
  }
  openDialogChangePassword(): void {
    const modal: any = this.modal.create({
      nzTitle: "เปลี่ยนแปลงรหัสผ่าน",
      nzContent: ModalChangePasswordComponent,
      nzData: this.user,
      nzFooter: [
        {
          label: "ปิด",
          onClick: () => modal.destroy(),
        },
        {
          label: "ยืนยัน",
          type: "primary",
          loading: false,
          onClick(): void {
            const instance = modal.getContentComponent();
            for (const i in instance.validateForm.controls) {
              instance.validateForm.controls[i].markAsDirty();
              instance.validateForm.controls[i].updateValueAndValidity();
            }

            if (instance.validateForm.valid) {
              this.loading = true;
              this.label = "กรุณารอสักครู่...";
              instance.authService
                .changePassword(
                  instance.validateForm.controls["password"].value
                )
                .subscribe(
                  (resp: any) => {
                    modal.destroy();
                    instance.message.success("เปลี่ยนรหัสผ่าน สำเร็จ");
                    setTimeout(() => {
                      instance.authService.logout();
                    }, 1000);
                  },
                  (err: Error) => {
                    modal.destroy();
                  }
                );
            }
          },
        },
      ],
    });
  }
}
