import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WheelService {
  baseUrl = environment.apiUrl;
  token = localStorage.getItem("token");
  constructor(private http: HttpClient) {}

  getPlayerTicketRemaining(data: { username: string, code: string }): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + "/wallets/v1/player-ticket/remaining", {
      headers: httpHeaders,
      params: data
    });
  }

  getConfigurationWheel(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + "/configuration/v1/client/wheel", {
      headers: httpHeaders,
    });
  }

  getWheelDraw(data: { player_id: string, username: string }): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "api-key": environment.apiKey,
    });
    return this.http.get(this.baseUrl + "/wallets/v1/wheel-draw", {
      headers: httpHeaders,
      params: data
    });
  }
}
