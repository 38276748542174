import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MockDataService {
  constructor() {}

  getMockData(page: number, pageSize: number) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 130,
      sumAmount: 8008330,
      totalAmount: "8,008,330.00",
      data: this.mockData(page, pageSize),
    });
  }
  mockData(page: number, pageSize: number) {
    const data: any = [];
    for (let i = (page - 1) * pageSize + 1; i <= page * pageSize; i++) {
      data.push({});
    }
    return data;
  }

  getGame(provider: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let item: any[] = [
      {
        image: "../../assets/image/game/pg-1.png",
        gameCode: "87",
        gameName: "Treasures of Aztec",
        gameProvider: "PG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/pg-2.png",
        gameCode: "89",
        gameName: "lucky neko",
        gameProvider: "PG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/pg-3.png",
        gameCode: "74",
        gameName: "Mahjong Ways 2",
        gameProvider: "PG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/pg-4.png",
        gameCode: "65",
        gameName: "Mahjong Ways",
        gameProvider: "PG",
        gameType: "game",
      },
    ];
    return of({
      totalRecord: item.length,
      data: item,
    });
  }

  getGameProvider(type: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    let item: any[] = [];
    item = this.allGame().filter((ele: any) => ele.gameType === type);
    return of({
      totalRecord: item.length,
      data: item,
    });
  }

  allGame() {
    const data: any = [
      {
        image: "../../assets/image/game/game-01.jpg",
        gameCode: "SEXY",
        gameName: "SEXY",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-02.jpg",
        gameCode: "PG",
        gameName: "PG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-03.jpg",
        gameCode: "PP",
        gameName: "PRAGMATICSlot",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-04.jpg",
        gameCode: "BP",
        gameName: "BluePrint",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-05.jpg",
        gameCode: "YGG",
        gameName: "YGG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-06.jpg",
        gameCode: "CQ9",
        gameName: "CQNine",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-07.jpg",
        gameCode: "QT",
        gameName: "QT",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-08.jpg",
        gameCode: "NAGA",
        gameName: "NAGA",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-09.jpg",
        gameCode: "RELAX",
        gameName: "RELAXSlot",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-10.jpg",
        gameCode: "SPN",
        gameName: "SPINIX",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-11.jpg",
        gameCode: "XO",
        gameName: "XO",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-12.jpg",
        gameCode: "MP",
        gameName: "MPoker",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-13.jpg",
        gameCode: "HB",
        gameName: "HABANERO",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-14.jpg",
        gameCode: "YL",
        gameName: "YL",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-15.jpg",
        gameCode: "FG",
        gameName: "FG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-16.jpg",
        gameCode: "KM",
        gameName: "KINGMAKER",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-17.jpg",
        gameCode: "KA",
        gameName: "KA",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-18.jpg",
        gameCode: "JK",
        gameName: "JOKER",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-19.jpg",
        gameCode: "SP",
        gameName: "SIMPLEPLAY",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-20.jpg",
        gameCode: "L22",
        gameName: "LIVE22",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-21.jpg",
        gameCode: "MG",
        gameName: "MG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-22.jpg",
        gameCode: "JILI",
        gameName: "JILI",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-23.jpg",
        gameCode: "JDB",
        gameName: "JDB",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-24.jpg",
        gameCode: "SPD",
        gameName: "SPADE",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-25.jpg",
        gameCode: "EVO",
        gameName: "EVOPLAY",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-26.jpg",
        gameCode: "GPS",
        gameName: "GPS",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-27.jpg",
        gameCode: "AMEBA",
        gameName: "AMEBA",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-28.jpg",
        gameCode: "RT",
        gameName: "RT",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-29.jpg",
        gameCode: "RSG",
        gameName: "RSG",
        gameType: "game",
      },
      {
        image: "../../assets/image/game/game-30.jpg",
        gameCode: "DG",
        gameName: "DGCasino",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-31.jpg",
        gameCode: "AB",
        gameName: "ALLBETCasino",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-32.jpg",
        gameCode: "RCG",
        gameName: "RCG",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-33.jpg",
        gameCode: "WO",
        gameName: "WORLD",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-34.jpg",
        gameCode: "PPL",
        gameName: "PRAGMATIC-LIVE",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-35.jpg",
        gameCode: "EVO",
        gameName: "EVOLUTION",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-36.jpg",
        gameCode: "SA",
        gameName: "SA-CASINO",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-37.jpg",
        gameCode: "WM",
        gameName: "WM",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-38.jpg",
        gameCode: "N2LIVE",
        gameName: "N2LIVE",
        gameType: "casino",
      },
      {
        image: "../../assets/image/game/game-39.jpg",
        gameCode: "SABA",
        gameName: "SABA-SPORT",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-40.jpg",
        gameCode: "TGS",
        gameName: "TIGER-SPORT",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-41.jpg",
        gameCode: "TGB",
        gameName: "TIGER-BOXING",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-42.jpg",
        gameCode: "PY",
        gameName: "pharaoh888",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-43.jpg",
        gameCode: "ESPORT",
        gameName: "E-SPORT",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-44.jpg",
        gameCode: "CF",
        gameName: "CockFight",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-45.jpg",
        gameCode: "SBO",
        gameName: "SBO",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-46.jpg",
        gameCode: "UG",
        gameName: "UG",
        gameType: "sport",
      },
      {
        image: "../../assets/image/game/game-47.jpg",
        gameCode: "WW",
        gameName: "WW",
        gameType: "sport",
      },
    ];
    return data;
  }
  getBanner() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 5,
      data: [
        { image: "../../assets/image/banner.png" },
        { image: "../../assets/image/banner.png" },
      ],
    });
  }
  getPomotions() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 5,
      data: [
        {
          image: "../../assets/image/promotion/promo-1.jpg",
          detail: "ค่าคอมคาสิโน 0.4%",
        },
        {
          image: "../../assets/image/promotion/promo-2.jpg",
          detail: "ค่าคอมกีฬา 0.4%",
        },
        {
          image: "../../assets/image/promotion/promo-3.jpg",
          detail: "สมาชิกใหม่ 200 บาทรับเพิ่ม 88 บาท",
        },
        {
          image: "../../assets/image/promotion/promo-4.jpg",
          detail: "ค่าคอมสล็อต 0.3%",
        },
        {
          image: "../../assets/image/promotion/promo-5.jpg",
          detail: "ฝากแรกของวันรับเพิ่ม 10%, สูงสุด 300",
        },
      ],
    });
  }
  getbank() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 11,
      data: [
        { code: "SCB", name: "scb" },
        { code: "KBANK", name: "kbank" },
        { code: "KTB", name: "ktb" },
        { code: "TRUE", name: "true" },
        { code: "TTB", name: "ttb" },
        { code: "BAY", name: "bay" },
        { code: "GSB", name: "gsb" },
        { code: "BBL", name: "bbl" },
        { code: "UOB", name: "uob" },
        { code: "HSBC", name: "hsbc" },
      ],
    });
  }
  genLinkRef(user: any) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      urlRef: "aaabbb",
      linkInvite: "www.google.com",
    });
  }
  getHistory() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 3,
      data: [
        {
          date: "12-07-2023",
          bet: 0,
          commission: 0,
          winloss: 2.5,
          summary: 2.5,
        },
        {
          date: "13-07-2023",
          bet: 0,
          commission: 0,
          winloss: 2.5,
          summary: 2.5,
        },
        {
          date: "14-07-2023",
          bet: 0,
          commission: 0,
          winloss: 2.5,
          summary: 2.5,
        },
      ],
    });
  }
  getDownlineByDate() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 1,
      data: [
        { provider: "PG", bet: 0, commission: 0, winloss: 2.5, summary: 2.5 },
      ],
    });
  }
  getDownlineByProvider() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 3,
      data: [
        {
          gameName: "SLOT-Lucky Neko Neko-PG",
          date: "12-03-2023 17:22:45",
          bet: 0,
          commission: 0,
          winloss: 2.4,
          summary: 2.5,
        },
        {
          gameName: "SLOT-Lucky Neko-PG",
          date: "12-03-2023 17:23:32",
          bet: 0,
          commission: 0,
          winloss: 0.1,
          summary: 2.5,
        },
      ],
    });
  }
  getAffiliate() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return of({
      totalRecord: 4,
      data: [
        { type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
        { type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
        { type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
        { type: "LOTTO", lv1: "0%", lv2: "0%" },
      ],
    });
  }
  getDownlineAffiliate(type: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    const data = [
      { gameProvider: "PGSlot", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "YGG", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "CQNine", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "QT", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "RELAX", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "PG-Gaming", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "MPoker", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "HABANERO", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "YL", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "FG", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      {
        gameProvider: "AWC(KINGMAKER)",
        type: "SLOT",
        lv1: "0.3%",
        lv2: "0.1%",
      },
      { gameProvider: "KA", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "JOKER", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "GOLDY", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "SIMPLEPLAY", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "LIVE22", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "MG", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AWC(JILI)", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "JDB", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "PRAGMATIC", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AWC(SPADE)", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AMEBA", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "EVOPLAY", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "GPS", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "RT", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "RSG", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "PG", type: "SLOT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "SABA-SPORT", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "SABA", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "TIGER-SPORT", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "TIGER-BOXING", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "pharaoh888", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AWC(E1SPORT)", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AWC(SV388)", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "SBO", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "UG", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "WW", type: "SPORT", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "WORLD", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "Star-DNA", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "SA-CASINO", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      {
        gameProvider: "PRAGMATIC-LIVE",
        type: "CASINO",
        lv1: "0.3%",
        lv2: "0.1%",
      },
      { gameProvider: "WM", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "DG", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "N2LIVE", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "ALLBET", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "AWC(SEXY)", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "EVOLUTION", type: "CASINO", lv1: "0.3%", lv2: "0.1%" },
      { gameProvider: "", type: "LOTTO", lv1: "0%", lv2: "0%" },
    ];
    let item: any[] = [];
    item = data.filter((ele: any) => ele.type === type);
    return of({
      totalRecord: item.length,
      data: item,
    });
  }
}
