import { Component, OnInit, Input } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { PlayersService } from "src/app/_service/players.service";
import { UtilService } from "src/app/_service/util.service";
import { NzUploadChangeParam } from "ng-zorro-antd/upload";
import { Observable, Observer } from "rxjs";
import { NzUploadFile } from "ng-zorro-antd/upload";

@Component({
  selector: "app-modal-deposit",
  templateUrl: "./modal-deposit.component.html",
  styleUrls: ["./modal-deposit.component.scss"],
})
export class ModalDepositComponent implements OnInit {
  @Input() lineLink!: string | null;
  token = localStorage.getItem("token");
  user: any = {};
  bank: any = {};
  loading = false;
  isPending = false;
  urlUpload?: any;
  avatarUrl: string = "";
  bankCode: string = "";
  listOfData: any[] = [];
  valueBankAcc: string = "";
  base64Data: string | undefined;

  constructor(
    public util: UtilService,
    private message: NzMessageService,
    public playersService: PlayersService
  ) {}

  ngOnInit(): void {
    this.agentDepositAccount();
    this.getDepositTxn();
  }
  //--------------------------------
  agentDepositAccount() {
    this.playersService.agentDepositAccount().subscribe((resp: any) => {
      if (resp) {
        this.bank = resp;
        this.bankCode = resp ? resp.bank_name.toLowerCase() : "";
      }
    });
  }
  getDepositTxn() {
    this.isPending = false;
    this.playersService.getDepositTxn().subscribe((resp: any) => {
      this.listOfData = resp.deposits;
      for (let item of this.listOfData) {
        if (item.status == "pending") {
          this.isPending = true;
        }
      }
    });
  }
  //
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please upload PNG or JPEG images only.");
        return;
      }
      if (file.size > 5000000) {
        alert("ขนาดไฟล์สูงสุดคือ 5mb");
        return;
      }
      this.playersService.uploadSlip(file, this.bank).subscribe((resp: any) => {
        this.message.success("ทำรายการสำเร็จ");
        this.getDepositTxn();
      });
    }
  }
  private readFileAsBase64(file: File) {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.base64Data = e.target.result;
    };
    reader.readAsDataURL(file);
  }
}
