import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ImageGameService {
  urlCDNprovider = environment.urlImgCDN + "/provider";

  constructor() {}

  getImgProvider(provider: string, gameType: string) {
    let provImage = "";
    if (provider) {
      provImage =
        "../../assets/image/provider/" + gameType + "/" + provider + ".png";
    } else {
      provImage = "../../assets/image/mock_game.png";
    }
    return provImage;
  }
}
