import { Component, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { PromotionsService } from "src/app/_service/promotions.service";
import { PlayersService } from "src/app/_service/players.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-promotion",
  templateUrl: "./promotion.component.html",
  styleUrls: ["./promotion.component.scss"],
})
export class PromotionComponent implements OnInit {
  mockImg = "assets/image/mock_game.png";
  listPromotion: any[] = [];
  isVisibleLogin = false;
  isLogin: any;
  isVisible = false;
  data: any;

  constructor(
    private authService: AuthService,
    private message: NzMessageService,
    private router: Router,
    private promotionsService: PromotionsService,
    private playersService: PlayersService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.isLogin = this.authService.loggedIn();
    if (this.isLogin) {
      this.getListPromotionByPlayer();
    } else {
      this.getListPromotion();
    }
  }

  getListPromotion() {
    const agent_code = environment.agentCode;
    this.promotionsService.getPromotions(agent_code).subscribe((resp) => {
      if (resp) {
        resp.forEach((item: any) => {
          this.mappingPromotionImage(item);
        });
      }
    });
  }

  getListPromotionByPlayer() {
    this.listPromotion = [];
    this.promotionsService.getPromotionsByPlayer().subscribe((resp) => {
      if (resp) {
        resp.forEach((item: any) => {
          this.mappingPromotionImage(item);
        });
      }
    });
  }

  mappingPromotionImage(item: any) {
    let promo_id: string;
    if (this.isLogin) {
      promo_id = item.id;
    } else {
      promo_id = item.image;
    }
    this.promotionsService.getPromotionImage(promo_id).subscribe({
      next: (resp) => {
        item.image = URL.createObjectURL(resp);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.listPromotion.push(item);
      },
    })
  }

  gotoPromotion() {
    this.router.navigateByUrl("profile/3");
  }
  onLoaded(isFallback: boolean) {
    // make somthing based on 'isFallback'
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }

  showModal(item: any): void {
    this.isVisible = true;
    this.data = item;
  }

  handleOk(id: string): void {
    this.acceptPromotion(id);
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  acceptPromotion(id: string): void {
    this.playersService.playerAcceptPromotion(id).subscribe((resp) => {
      if (resp) {
        this.message.success("รับโปรโมชั่นสำเร็จ");
        this.getListPromotionByPlayer();
      } else {
        this.message.error(resp.message);
      }
    });
  }
}
