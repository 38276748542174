import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_service/auth.service';
import { CookieUtilService } from 'src/app/_service/cookie-util.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  @Output() public hamdleGameType = new EventEmitter();
  @Input() checkType: any;
  checkPath = "";
  isLogin: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    public cookieUtilService: CookieUtilService,
  ) { }

  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    this.checkPath = this.router.url;
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  handleType(type: string) {
    this.cookieUtilService.setCookie('gameType', type, 0);
    this.hamdleGameType.emit(type);
    if (this.checkPath !== '/') {
      this.router.navigateByUrl("/");
    }
  }
}
