<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <div class="">
    <app-banner></app-banner>
    <app-header-menu></app-header-menu>
  </div>
  <div class="mt-10"></div>
  <app-marquee></app-marquee>
</ng-template>
<ng-template #content>
  <!-- <app-game-list checkType="SLOT"></app-game-list>

  <app-game-list checkType="LIVE"></app-game-list>

  <app-game-list checkType="SPORT"></app-game-list> -->

  <div *ngIf="isVisibleLists">
    <app-game-list-by-id
      checkType="SLOT"
      [typeId]="this.slotId"
    ></app-game-list-by-id>
    <app-game-list-by-id
      checkType="LIVE"
      [typeId]="this.liveId"
    ></app-game-list-by-id>
    <app-game-list-by-id
      checkType="SPORT"
      [typeId]="this.sportId"
    ></app-game-list-by-id>
  </div>
  <!-- <div class="panel-gray bor-10">
    <div class="title">
      <img src="assets/image/i-hot.svg" alt="" />
      <h2>เกมส์แนะนำ</h2>
    </div>
    <app-game-list-type groupCode="RECOMMEND"></app-game-list-type>
  </div>
  <div class="panel-gray bor-10">
    <div class="title">
      <img src="assets/image/i-hot.svg" alt="" />
      <h2>เกมยอดฮิต</h2>
    </div>
    <app-game-list-type groupCode="HIT"></app-game-list-type>
  </div> -->
</ng-template>
