import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieUtilService } from "src/app/_service/cookie-util.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  current = 0;
  phone = "";
  dataRef: any;
  param = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cookieUtil: CookieUtilService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.params.subscribe((params: any) => {
      if (params.seller_code != undefined) {
        this.param = params.seller_code.toUpperCase();
        this.cookieUtil.setCookie("seller_code", this.param);
      } else {
        this.param = "";
        this.cookieUtil.delCookie("seller_code");
      }
    });
  }
  // --------------------------------
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  public stepNext(event: any) {
    if (event) {
      this.current += 1;
      this.dataRef = event;
    } else {
      this.current = 0;
    }
  }
}
