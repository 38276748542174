import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../_service/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  jwtHelper = new JwtHelperService();
  isGetAT: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private message: NzMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.status === 401 && !this.isGetAT) {
          this.isGetAT = true;
          this.router.navigateByUrl("/");
        } else if (err.error.message == "ไม่พบ seller") {
          const error = err.error.message || err.statusText;
          return throwError(error);
        } else if (err.error.message == "mongo: no documents in result") {
          const error = err.error.message || err.statusText;
          return throwError(error);
        } else {
          const error = err.error.message || err.statusText;
          this.message.error(err.error.display_message);
          return throwError(error);
        }
        return throwError("Something went wrong!");
      })
    );
  }
}
