import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { AuthService } from "src/app/_service/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { CookieUtilService } from "src/app/_service/cookie-util.service";
import { PlayersService } from "src/app/_service/players.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component implements OnInit {
  @Output() public handleStep = new EventEmitter();
  validateForm!: UntypedFormGroup;
  loading = false;
  current = 0;
  passwordVisible = false;
  cfPasswordVisible = false;
  sellerCode = "";
  sellerOptions = [{ value: "notSpecified", label: "ไม่ระบุ" }];

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private message: NzMessageService,
    private playerService: PlayersService,
    public cookieUtil: CookieUtilService
  ) {}

  ngOnInit(): void {
    this.getSellerList(environment.agentCode);
    if (this.cookieUtil.cookieExists("seller_code")) {
      this.sellerCode = this.cookieUtil.getCookie("seller_code");
    }
    this.validateForm = this.fb.group({
      seller_code: [""],
      seller: ["notSpecified"],
      tel: [null, [Validators.required]],
      password: [null, [Validators.required]],
      confirm_password: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });

    if (this.cookieUtil.cookieExists("seller_code")) {
      this.validateForm.controls["seller_code"].setValue(this.sellerCode);
      this.validateForm.controls["seller_code"].disable({ emitEvent: false });
    }
  }
  getSellerList(agent: string): void {
    this.sellerOptions = [{ value: "notSpecified", label: "ไม่ระบุ" }];
    this.playerService.getSellerList(agent).subscribe(
      (res: any) => {
        // console.log(res);
        if (res) {
          res.sellerResponses.forEach((item: any) => {
            this.sellerOptions.push({
              value: item.seller_code,
              label: item.display_name,
            });
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  sellerchange(): void {
    if (this.validateForm.controls["seller"].value != "notSpecified") {
      this.validateForm.controls["seller_code"].disable({ emitEvent: false });
    } else {
      this.validateForm.controls["seller_code"].enable({ emitEvent: false });
    }
  }
  submitForm(): void {
    const controls = this.validateForm.controls;
    if (this.validateForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    } else {
      this.current += 1;
      let seller_data = "";
      if (controls["seller"].value == "notSpecified") {
        seller_data = controls["seller_code"].value;
      } else {
        seller_data = controls["seller"].value;
      }
      const data = {
        seller_code: seller_data,
        tel: controls["tel"].value,
        password: controls["password"].value,
        confirm_password: controls["confirm_password"].value,
      };
      this.handleStep.emit(data);
    }

    this.loading = true;
  }
  confirmationValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls["password"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.validateForm.controls["confirm_password"].updateValueAndValidity()
    );
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.validateForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
