import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-table-downline",
  templateUrl: "./table-downline.component.html",
  styleUrls: ["./table-downline.component.scss"],
})
export class TableDownlineComponent implements OnInit {
  @Input() downline: any;
  listOfData: any[] = [];
  constructor() {}

  ngOnInit(): void {}
}
