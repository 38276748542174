<!-- <form nz-form [formGroup]="forgotPWForm" class="forgot-form">
    <nz-form-item>
        <nz-form-control nzErrorTip="กรุณากรอก เบอร์มือถือที่ใช้เข้าระบบ">
            <label>เบอร์มือถือ (สำหรับเข้าระบบ)</label>
            <input type="tel" nz-input formControlName="userName" placeholder="กรอกเบอร์มือถือ ที่ใช้เข้าระบบ" mask="0000000000"/>
        </nz-form-control>
    </nz-form-item>
</form> -->

<div>
    <h2 class="tx-white">ลืมรหัสผ่าน</h2>
    <nz-steps [nzCurrent]="current" [nzProgressDot]="progressTemplate" class="custom-step step-forgotpassword">
        <nz-step nzTitle="เบอร์โทรศัพท์"></nz-step>
        <nz-step nzTitle="ยืนยัน OTP"></nz-step>
        <nz-step nzTitle="รหัสผ่านใหม่"></nz-step>
    </nz-steps>

    <form nz-form [formGroup]="forgotPWForm" class="inputForm mt-30">
        <div class="panel-step step1" *ngIf="this.current == 0">
            <nz-form-item>
                <nz-form-control nzErrorTip="กรุณากรอก เบอร์โทรศัพท์">
                    <label>เบอร์โทรศัพท์</label>
                    <input type="tel" nz-input formControlName="tel" placeholder="กรอกเบอร์เบอร์โทรศัพท์" mask="0000000000"/>
                </nz-form-control>
            </nz-form-item>
            <button class="btn-green m-10" (click)="requestOTP()">ถัดไป</button>
        </div>
        <!--  -->
        <div class="panel-step step2" *ngIf="this.current == 1">
            <nz-form-item>
                <nz-form-control nzErrorTip="กรุณากรอก OTP">
                    <label>ระบุหมายเลข OTP</label>
                    <input type="tel" nz-input formControlName="otp" placeholder=""/>
                </nz-form-control>
            </nz-form-item>
            <label>ระบบได้ส่งรหัส OTP ในรูปแบบ SMS ไปยังหมายเลขโทรศัพท์ {{this.forgotPWForm.controls["tel"].value}}</label>
            <div>
                <button class="btn-secondary m-10" (click)="nextStep()">เปลี่ยนหมายเลขโทรศัพท์</button>
                <a class="tx-red display-inline-block m-10">ส่งรหัส OTP อีกครั้ง</a>
                <button class="btn-green m-10" (click)="nextStep()">ถัดไป</button>
            </div>
        </div>
        <!--  -->
        <div class="panel-step step2" *ngIf="this.current == 2">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorTpl1">
                    <label nzFor="password">รหัสผ่านใหม่</label>
                    <input
                        nz-input 
                        minlength="6"
                        maxlength="20"
                        type="password"
                        formControlName="password"
                        (ngModelChange)="updateConfirmValidator()" />
                    <ng-template #errorTpl1 let-control>
                        <ng-container *ngIf="control.hasError('required')">กรุณาระบุ</ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">ระบุรหัสผ่านอย่างน้อย 6 หลัก</ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">รหัสผ่านต้องไม่เกิน 100 หลัก</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorTpl">
                    <label nzFor="confirm_password">ยืนยันรหัสผ่าน</label>
                    <input 
                        nz-input
                        minlength="6"
                        maxlength="20"
                        type="password"
                        formControlName="confirm_password" />
                    <ng-template #errorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">กรุณาระบุ</ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">ระบุรหัสผ่านอย่างน้อย 6 หลัก</ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">รหัสผ่านต้องไม่เกิน 100 หลัก</ng-container>
                        <ng-container *ngIf="control.hasError('confirm')">รหัสผ่านไม่ตรงกัน</ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <div class="center">
                <button class="btn-secondary m-10" (click)="confirm()">ยืนยัน</button>
            </div>
        </div>
    </form>
    
    <span class="tx-white">พบปัญหา
        <a class="tx-red underline">ติดต่อฝ่ายบริการลูกค้า</a>
    </span>
</div>


<ng-template #progressTemplate let-dot let-status="status" let-index="index" class="step-forgotpassword">
  <span style="margin-left: -100%;">
    <ng-template [ngTemplateOutlet]="dot"></ng-template>
  </span>
</ng-template>
