<!--  -->
<div class="relative">
  <app-header [lineId]="lineId" [lineLink]="lineLink"></app-header>
  <app-side-menu [lineId]="lineId" [lineLink]="lineLink" />
  <div class="bg-main" [ngClass]="{ '-isLogin': isLogin }">
    <div class="headerline"></div>
    <!-- <img src="assets/image/bg-pattern.png" alt="" class="img-bg" /> -->
    <ng-container *ngTemplateOutlet="banner"></ng-container>
    <div class="zIndex container">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>
  <div class="headerline"></div>
  <app-footer-menu></app-footer-menu>
  <a [href]="lineLink" class="float" target="_blank"
    ><img src="assets/image/line-float-item.png" alt=""
  /></a>
</div>
<app-footer [lineId]="lineId" [lineLink]="lineLink"></app-footer>
