import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/_service/auth.service";
import { ModalChangePasswordComponent } from "../modal/modal-change-password/modal-change-password.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CookieUtilService } from "src/app/_service/cookie-util.service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent {
  @Output() public handleNavMenu = new EventEmitter();
  @Input() isVisible?: boolean;
  @Input() checkType: any;
  @Input() lineId!: string | null;
  @Input() lineLink!: string | null;
  version = environment.appVersion;
  checkPath = "";
  isLogin = false;
  user: any = {};

  constructor(
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService,
    public cookieUtilService: CookieUtilService
  ) {}

  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
    this.checkPath = this.router.url;
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  linelink() {
    const lineUrl = this.lineLink;

    if (lineUrl) {
      window.open(lineUrl, "_blank");
    } else {
      console.error("ไม่พบ Line URL");
    }
  }
}
