<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <app-banner></app-banner>
</ng-template>
<ng-template #content>
  <div class="promotion-panel">
    <div
      class="promo-block relative"
      (click)="showModal(item)"
      *ngFor="let item of listPromotion as folder"
    >
      <div
        *ngIf="this.isLogin && item.is_accepted"
        class="absolute promotion-bagde"
      >
        ทำอยู่
      </div>
      <img
        src-fallback="{{ this.mockImg }}"
        (loaded)="onLoaded($event)"
        [src]="item.image"
        alt=""
      />
      <p class="p-10 m-0">{{ item.name }} {{ item.description }}</p>
    </div>
  </div>
  <nz-modal
    [nzWidth]="450"
    nzClassName="bg-modal"
    nzWrapClassName="modal-login"
    [(nzVisible)]="isVisible"
    :nzMaskClosable="false"
    (nzOnCancel)="handleCancel()"
    className="modalStyle"
  >
    <ng-container *nzModalContent>
      <div class="modal-img">
        <img
          src-fallback="{{ this.mockImg }}"
          (loaded)="onLoaded($event)"
          [src]="data.image"
          alt=""
        />
      </div>

      <div class="tx-white modal-header text-center">{{ data.name }}</div>
      <div class="tx-white modal-discription">{{ data.description }}</div>
      <div
        class="text-center"
        *ngIf="
          this.isLogin && data.active_type == 'receive' && !data.is_accepted
        "
      >
        <button class="btn btn-promotion" (click)="handleOk(data.id)">
          รับโปรโมชั่น
        </button>
      </div>
      <div class="tx-white text-center">
        พบปัญหา
        <a class="tx-red underline modal-footer">ติดต่อฝ่ายบริการลูกค้า</a>
      </div>
    </ng-container>
  </nz-modal>
</ng-template>
