import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_service/auth.service";
@Component({
  selector: "app-footer-menu",
  templateUrl: "./footer-menu.component.html",
  styleUrls: ["./footer-menu.component.scss"],
})
export class FooterMenuComponent {
  isVisibleWithdraw = false;
  isVisibleDeposit = false;
  isVisibleRegister = false;
  isVisibleLogin = false;
  isLogin = false;
  constructor(private router: Router, private authService: AuthService) {}
  ngOnInit(): void {
    this.isLogin = this.authService.loggedIn();
  }
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }

  handleModalRegister() {
    this.isVisibleRegister = !this.isVisibleRegister;
  }
  handleModalLogin(): void {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
  handleModalDeposit() {
    this.isVisibleDeposit = !this.isVisibleDeposit;
  }
  handleModalWithdraw() {
    this.isVisibleWithdraw = !this.isVisibleWithdraw;
  }
  closeModalWithdraw(value: any) {
    this.isVisibleWithdraw = !value;
  }
}
