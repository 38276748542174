import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorInterceptor } from "./_guard/error.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe, registerLocaleData } from "@angular/common";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { QrCodeModule } from "ng-qrcode";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { NZ_I18N, th_TH, en_US } from "ng-zorro-antd/i18n";
import en from "@angular/common/locales/en";
import th from "@angular/common/locales/th";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzUploadModule } from "ng-zorro-antd/upload";

import { ImgFallbackModule } from "ngx-img-fallback";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "./modules/main/main.component";
import { LoginComponent } from "./modules/login/login.component";
import { RegisterComponent } from "./modules/register/register.component";
import { AffiliateComponent } from "./modules/affiliate/affiliate.component";
import { LaunchGameComponent } from "./modules/launch-game/launch-game.component";
import { PromotionComponent } from "./modules/promotion/promotion.component";
import { ReferralComponent } from "./modules/referral/referral.component";
import { SiteMaintenanceComponent } from "./modules/site-maintenance/site-maintenance.component";
import { WheelComponent } from "./modules/wheel/wheel.component";
import { GameListComponent } from "./modules/game/game-list/game-list.component";
import { GameSlotIdComponent } from "./modules/game/game-slot-id/game-slot-id.component";
import { BannerComponent } from "./components/banner/banner.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderMenuComponent } from "./components/header-menu/header-menu.component";
import { ModalChangePasswordComponent } from "./components/modal/modal-change-password/modal-change-password.component";
import { ModalProfileComponent } from "./components/modal/modal-profile/modal-profile.component";
import { ModalDepositComponent } from "./components/modal/modal-deposit/modal-deposit.component";
import { ModalWithdrawComponent } from "./components/modal/modal-withdraw/modal-withdraw.component";
import { SectionBgComponent } from "./components/section-bg/section-bg.component";
import { TableHistoryComponent } from "./components/table/table-history/table-history.component";
import { TableBetComponent } from "./components/table/table-bet/table-bet.component";
import { TableWinlossComponent } from "./components/table/table-winloss/table-winloss.component";
import { TableDownlineComponent } from "./components/table/table-downline/table-downline.component";
import { TableCommissionsComponent } from "./components/table/table-commissions/table-commissions.component";
import { TableTypeCommissionsComponent } from "./components/table/table-type-commissions/table-type-commissions.component";
import { GameListTypeComponent } from "./modules/game/game-list-type/game-list-type.component";
import { NavMenuComponent } from "./components/nav-menu/nav-menu.component";
import { RateMuayComponent } from "./modules/muay/rate-muay/rate-muay.component";
import { MuayTodayComponent } from "./modules/muay/muay-today/muay-today.component";
import { LiveScoreComponent } from "./modules/muay/live-score/live-score.component";
import { MuayThaiComponent } from "./modules/muay/muay-thai/muay-thai.component";
import { LiveComponent } from "./modules/muay/live/live.component";
import { WebComponent } from "./modules/muay/web/web.component";
import { ModalHistoryComponent } from "./components/modal/modal-history/modal-history.component";
import { ModalFinancialComponent } from "./components/modal/modal-financial/modal-financial.component";
import { FormLoginComponent } from "./components/form-login/form-login.component";
import { ModalForgotPasswordComponent } from "./components/modal/modal-forgot-password/modal-forgot-password.component";
import { Step1Component } from "./components/step-register/step1/step1.component";
import { Step2Component } from "./components/step-register/step2/step2.component";
import { FinancialComponent } from "./modules/financial/financial.component";
import { HistoryComponent } from "./modules/history/history.component";
import { SectionIsloginComponent } from "./components/section-islogin/section-islogin.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { SportComponent } from "./modules/game/sport/sport.component";
import { CasinoComponent } from "./modules/game/casino/casino.component";
import { LottoComponent } from "./modules/game/lotto/lotto.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { MarqueeComponent } from "./components/marquee/marquee.component";
import { SlotComponent } from "./modules/game/slot/slot.component";
import { ModalRegisterComponent } from "./components/modal/modal-register/modal-register.component";
import { FooterMenuComponent } from "./components/footer-menu/footer-menu.component";
import { GameListByIdComponent } from "./modules/game/game-list-by-id/game-list-by-id.component";
import { ClipboardModule } from '@angular/cdk/clipboard';

registerLocaleData(th);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    AffiliateComponent,
    LaunchGameComponent,
    PromotionComponent,
    ReferralComponent,
    SiteMaintenanceComponent,
    WheelComponent,
    GameListComponent,
    GameSlotIdComponent,
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    ModalChangePasswordComponent,
    ModalProfileComponent,
    ModalDepositComponent,
    ModalWithdrawComponent,
    SectionBgComponent,
    RegisterComponent,
    TableHistoryComponent,
    TableBetComponent,
    TableWinlossComponent,
    TableDownlineComponent,
    TableCommissionsComponent,
    TableTypeCommissionsComponent,
    GameListTypeComponent,
    NavMenuComponent,
    RateMuayComponent,
    MuayTodayComponent,
    LiveScoreComponent,
    MuayThaiComponent,
    LiveComponent,
    WebComponent,
    ModalHistoryComponent,
    ModalFinancialComponent,
    FormLoginComponent,
    ModalForgotPasswordComponent,
    Step1Component,
    Step2Component,
    FinancialComponent,
    HistoryComponent,
    SectionIsloginComponent,
    CarouselComponent,
    SportComponent,
    CasinoComponent,
    LottoComponent,
    SideMenuComponent,
    MarqueeComponent,
    SlotComponent,
    ModalRegisterComponent,
    FooterMenuComponent,
    GameListByIdComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    QrCodeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NzTabsModule,
    NzCarouselModule,
    NzLayoutModule,
    NzRadioModule,
    NzFormModule,
    NzIconModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzInputNumberModule,
    NzInputModule,
    NzStepsModule,
    NzDatePickerModule,
    NzTypographyModule,
    NzMessageModule,
    NzModalModule,
    NzUploadModule,
    ImgFallbackModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ClipboardModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.domainApi],
        disallowedRoutes: [],
      },
    }),
  ],
  exports: [],
  providers: [
    DatePipe,
    provideNgxMask(),
    { provide: NZ_I18N, useValue: th_TH },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function tokenGetter() {
  return localStorage.getItem("token");
}
