<div class="menu-section">
  <ul class="menu-ul">
    <li class="list" (click)="goto('/')">
      <img src="assets/image/nav/nav-m-home.png" alt="" />
      <label>หน้าหลัก</label>
    </li>
    <li class="list" (click)="goto('/sport')">
      <img src="assets/image/nav/nav-m-sport.png" alt="" />
      <label>กีฬา</label>
    </li>
    <li class="list" (click)="goto('/casino')">
      <img src="assets/image/nav/nav-m-casino.png" alt="" />
      <label>คาสิโน</label>
    </li>
    <li class="list" (click)="goto('/slot')">
      <img src="assets/image/nav/nav-m-slot.png" alt="" />
      <label>สล็อต</label>
    </li>
    <li class="list" (click)="goto('/lotto')">
      <img src="assets/image/nav/nav-m-lotto.png" alt="" />
      <label>หวย</label>
    </li>
    <li class="list" (click)="goto('/spin')">
      <img src="assets/image/nav/nav-m-wheel.png" alt="" />
      <label>วงล้อ</label>
    </li>
    <!-- <li class="list" [ngClass]="{ active: checkPath === '/affiliate' }" (click)="goto('affiliate')" *ngIf="isLogin">
        <img src="assets/image/nav/nav-affiliate.svg" alt="" />
        <label>หารายได้</label>
      </li>
      <li class="list" [ngClass]="{ active: checkPath === '/promotion' }" (click)="goto('promotion')">
        <img src="assets/image/nav/nav-promotion.svg" alt="" />
        <label>โปรโมชั่น</label>
      </li> -->
  </ul>
</div>
