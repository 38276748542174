import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { AuthService } from "src/app/_service/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { environment } from "src/environments/environment";
import { MockDataService } from "src/app/_service/mock-data.service";
import { PlayersService } from "src/app/_service/players.service";
import { UserRegister } from "src/app/_model/user";
import { Router } from "@angular/router";

@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
})
export class Step2Component implements OnInit {
  @Output() public handleStep = new EventEmitter();
  @Input() dataRef: any;

  validateForm!: UntypedFormGroup;
  agentCode = environment.agentCode;
  bankSelect: any[] = [];
  current = 0;
  account_no_maxlength = 10;
  loading = false;
  selected = "";

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private auth: AuthService,
    private message: NzMessageService,
    private playerService: PlayersService,
    private mockDataService: MockDataService
  ) {}

  ngOnInit(): void {
    this.getbankOption();
    this.validateForm = this.fb.group({
      account_no: ["", Validators.required],
      account_name: ["", Validators.required],
    });
  }
  getbankOption() {
    this.playerService.getBankOptions().subscribe((res: any) => {
      if (res) {
        res.forEach((item: any) => {
          this.bankSelect.push({
            code: item.bank_abbrev_en,
            name: item.bank_abbrev_en,
            maxlength:
              item.account_length.length > 2
                ? item.account_length.split(",")[1]
                : item.account_length,
          });
        });
      }
    });
  }

  numberchecked(event: any) {
    const regexPattern = /^[0-9]+$/;
    if (!regexPattern.test(event.data)) {
      let updatedValue = this.validateForm.controls.account_no.value.slice(
        0,
        -1
      );

      this.validateForm.controls.account_no.setValue(updatedValue);
    }
  }

  handleBankSelect(code: string) {
    this.bankSelect.forEach((item: any) => {
      if (code === item.code) {
        this.account_no_maxlength = item.maxlength;
      }
    });
    this.selected = code;
    if (this.selected == "TRUE") {
      this.validateForm.controls.account_no.setValue(this.dataRef.tel);
      this.validateForm.controls["account_no"].disable();
    } else {
      this.validateForm.controls.account_no.setValue("");
      this.validateForm.controls["account_no"].enable();
    }
  }
  
  submitForm(): void {
    const controls = this.validateForm.controls;
    if (this.validateForm.invalid || this.selected === "") {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    } else {
      this.loading = true;
      const data: any = {
        username: this.dataRef.tel,
        password: this.dataRef.password,
        tel: this.dataRef.tel,
        code: this.agentCode,
        credit: 0,
        status: "approve",
        is_validate_bank_account: false,
        bank_accounts: [
          {
            bank_name: this.selected,
            account_no: `${controls["account_no"].value}`,
            account_name: controls["account_name"].value,
            account_type: "deposit",
            is_active: true,
            prompt_pay_no: "",
          },
        ],
        register_revenue_configuration: [],
        seller_code: this.dataRef.seller_code,
      };
      this.playerService.registerPlayer(data).subscribe({
        next: (resp: any) => {
          this.auth.login(this.dataRef.tel, this.dataRef.password).subscribe({
            next: (resp: any) => {
              this.message
                .loading("กำลังเข้าสู่ระบบ...")
                .onClose!.subscribe(() => {
                  if (this.router.url === "/") {
                    window.location.reload();
                  } else {
                    this.router.navigateByUrl("/");
                  }
                });
            },
            error: (e) => {
              this.loading = false;
              this.message.error(e.error.display_message);
            },
            complete: () => {},
          });
        },
        error: (e) => {
          console.log(e);
          if (e.includes("หมายเลขบัญชีไม่ถูกต้อง")) {
            console.log("bank assount issue");
            this.loading = false;
          } else {
            this.loading = false;
            this.current = 0;
            this.handleStep.emit();
          }
        },
        complete: () => {},
      });
    }
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.validateForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
