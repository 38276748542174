<div class="content-profile">
  <div class="title">
    <img src="assets/image/header.png" alt="" />
    <span class="title-text">บัญชีของฉัน</span>
  </div>
  <img src="assets/image/profile_icon.png" alt="" class="img-profile" />
  <h5 class="profile-name">
    สวัสดี : <label>{{ this.user.preferred_username }}</label>
  </h5>
  <a (click)="openDialogChangePassword()" class="tx-yellow underline astyled">
    เปลี่ยนรหัสผ่าน
  </a>
  <div class="profile-detail mt-20">
    <div class="flex">
      <i [class]="'bank-logo bank bank-' + this.bankCodeIcon()"></i>
      <div class="account-data">
        <p>
          {{ this.bank_data.account_name }}
        </p>
        <p>
          {{ this.bank_data.account_no }}
        </p>
      </div>
    </div>
  </div>
  <div class="btn-submit btn-secondary w-fit px-20" (click)="logout()">
    ออกจากระบบ
  </div>
  <div class="mt-30">
    <div class="foot">
      <div>
        <span class="tx-white"
          >พบปัญหา
          <a
            [href]="lineLink"
            target="_blank"
            class="tx-yellow underline display-block fix-block"
            >ติดต่อฝ่ายบริการลูกค้า</a
          >
        </span>
      </div>
    </div>
  </div>
</div>
