<div class="title">
  <img src="assets/image/header.png" alt="" />
  <span class="title-text">ฝาก</span>
</div>
<div class="box-bank">
  <div class="box-fx-bank">
    <i [class]="'mr-10 xxxxl bank bank-' + this.bankCode"></i>
    <div class="bank-fx">
      <p class="m-0">{{ this.bank.account_name }}</p>
      <p class="m-0">{{ this.bank.account_no }}</p>
    </div>
    <p class=" btn-copy btn-secondary m-10" nz-typography [cdkCopyToClipboard]="bank.account_no">
      <span nz-icon nzType="copy" nzTheme="outline">คัดลอก</span>
    </p>
  </div>
</div>
<div class="center">
  <div class="box-warning">กรุณาโอนเงินโดยใช้บัญชีที่สมัครเท่านั้น</div>
</div>
<div class="center" *ngIf="this.bank.is_show_upload_slip">
  <p class="tx-yellow mt-20">อัพโหลดสลิป กรณีเงินไม่เข้าภายใน 3 นาที</p>
  <div class="input-file-box m-10">
    <input
      type="file"
      accept=".png, .jpeg, .jpg"
      name="file"
      id="file"
      class="inputfile"
      [disabled]="isPending"
      (change)="onFileSelected($event)"
    />
    <label for="file" class="btn-deposit btn-secondary px-20 w-fit">
      อัพโหลดสลิปเงินฝาก
    </label>
    <p class="text-sm tx-yellow">ขนาดไฟล์สูงสุด 5mb</p>
  </div>
</div>
<!--  -->
<div *ngIf="base64Data">
  <p>Base64 Data:</p>
  <textarea rows="10" cols="50">{{ base64Data }}</textarea>
</div>
<div class="m-10">
  <nz-table
    #basicTable
    class="custom-table"
    [nzData]="listOfData"
    [nzFrontPagination]="false"
    [nzScroll]="listOfData.length > 10 ? { y: '280px' } : {}"
  >
    <thead>
      <tr>
        <th nzWidth="140px">วันที่ฝาก</th>
        <th class="tx-r" nzWidth="70px">จำนวนเงิน</th>
        <th nzWidth="70px">สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ this.util.showToJsonDateTime(data.transaction_time) }}</td>
        <td class="tx-r">{{ data.amount | currency : "" : "" }}</td>
        <td [ngClass]="this.util.colorOfStatus(data.status)">
          {{ this.util.textTranslate(data.status) }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
<div class="mt-20">
  <div class="foot">
    <div>
      <span class="tx-white"
        >พบปัญหา
        <a
          [href]="lineLink"
          target="_blank"
          class="tx-yellow underline display-block fix-block"
          >ติดต่อฝ่ายบริการลูกค้า</a
        >
      </span>
    </div>
  </div>
</div>
