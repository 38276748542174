import { Component } from '@angular/core';

@Component({
  selector: 'app-rate-muay',
  templateUrl: './rate-muay.component.html',
  styleUrls: ['./rate-muay.component.scss']
})
export class RateMuayComponent {

}
