import { Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_service/auth.service";
import { CookieUtilService } from "src/app/_service/cookie-util.service";
import { ImageGameService } from "src/app/_service/image-game.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Input() lineId!: string | null;
  @Input() lineLink!: string | null;
  listProvider: any[] = [];
  isProfile = false;
  date = new Date();
  version = environment.appVersion;
  constructor(
    private router: Router,
    private authService: AuthService,
    private imageGameService: ImageGameService,
    private cookieUtilService: CookieUtilService
  ) {}
}
