import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieUtilService } from 'src/app/_service/cookie-util.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  constructor(
    private actRoute: ActivatedRoute,
    public cookieUtil: CookieUtilService,
  ) { }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params: any) => {
      const referralCode = params.get("code");
      const cookie = this.cookieUtil.cookieExists("referral");
      if (cookie) {
        this.cookieUtil.delCookie("referral");
      }
      const dateNow = new Date();
      dateNow.setDate(dateNow.getDate() + 7);
      this.cookieUtil.setCookie("referral", referralCode, dateNow);
      
    });
  }
}
