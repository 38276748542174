import { Component, Input, OnInit } from "@angular/core";
import { HistoryService } from "src/app/_service/history.service";
import { UtilService } from "src/app/_service/util.service";

@Component({
  selector: "app-modal-history",
  templateUrl: "./modal-history.component.html",
  styleUrls: ["./modal-history.component.scss"],
})
export class ModalHistoryComponent implements OnInit {
  @Input() isTitle: boolean = false;
  @Input() lineLink!: string | null;
  dataSourceWinloss: any[] = [];
  dataWinloss: any[] = [];
  dateList2 = "";
  isList2 = false;
  isList3 = false;

  listSubOfData: any[] = [];
  listSub2OfData: any[] = [];

  loading = false;
  isLoading = false;
  listOfData: any[] = [
    {
      date: "28-06-2023",
      bet: "0",
      commission: "0",
      winloss: "2.5",
      sum: "2.5",
    },
    {
      date: "28-06-2023",
      bet: "0",
      commission: "0",
      winloss: "2.5",
      sum: "2.5",
    },
  ];
  constructor(
    public util: UtilService,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.getWinloss();
  }
  getWinloss() {
    this.loading = true;
    this.historyService.getWinLose().subscribe(
      (resp: any) => {
        if (resp.code && resp.code == "404") {
          this.loading = false;
          return;
        }
        if (resp) {
          this.dataWinloss = [];
          resp.forEach((ele: any) => {
            this.dataWinloss.push({
              ...ele,
            });
          });
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }
  getHistoryByDate(date: any) {
    this.loading = true;

    this.isList2 = true;
    this.dateList2 = date;
    this.historyService.getWinLoseByDate(date).subscribe((resp: any) => {
      if (resp) {
        this.listSubOfData = [];
        resp.forEach((ele: any) => {
          this.listSubOfData.push({
            ...ele,
          });
        });
      }
      this.loading = false;
    });
  }

  closeList2() {
    this.isList2 = false;
    this.dateList2 = "";
  }

  getHistoryByGame(game: any) {
    this.loading = true;

    this.isList3 = true;
    this.historyService
      .getWinLoseByGame(this.dateList2, game)
      .subscribe((resp: any) => {
        if (resp) {
          this.listSub2OfData = [];
          resp.forEach((ele: any) => {
            this.listSub2OfData.push({
              ...ele,
            });
          });
        }
        this.loading = false;
      });
  }

  closeList3() {
    this.isList3 = false;
  }
}
