import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_service/auth.service";
import { GamesService } from "src/app/_service/games.service";

@Component({
  selector: "app-game-list-type",
  templateUrl: "./game-list-type.component.html",
  styleUrls: ["./game-list-type.component.scss"],
})
export class GameListTypeComponent implements OnInit {
  @Input() groupCode!: string;
  imgError = "assets/image/mock_game.png";
  isVisibleLogin = false;
  isLogin: boolean = false;
  listGames: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private gameService: GamesService,
  ) { }

  ngOnInit() {
    this.isLogin = this.authService.loggedIn();
    this.getGame();
  }

  getGame() {
    this.listGames = [];
    // this.gameService.getGameByGroup(groupCode).subscribe((resp: any) => {
    //   if (resp) {
    //     resp.forEach((item: any) => {
    //       this.listGames.push(item);
    //     });
    //   }
    // });
  }
  gotoLaunchGame(item: any) {
    if (this.isLogin) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/launch/" + item.id])
      );
      window.open(url, "_blank");
    } else {
      this.isVisibleLogin = true;
    }
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
  // ------------------------------------------------------------
  onLoaded(isFallback: boolean) {
    // make somthing based on 'isFallback'
  }
}
