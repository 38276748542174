import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { AuthService } from "src/app/_service/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { ConfirmPasswordValidator } from "src/app/auth/confirm-password.validator";
import { PlayersService } from "src/app/_service/players.service";
import { IPlayerResetPassword, IPlayerVerifyOtp } from "src/app/_model/player";

@Component({
  selector: "app-modal-forgot-password",
  templateUrl: "./modal-forgot-password.component.html",
  styleUrls: ["./modal-forgot-password.component.scss"],
})
export class ModalForgotPasswordComponent implements OnInit {
  @Output() public handleCloseModal = new EventEmitter();

  forgotPWForm!: UntypedFormGroup;
  current = 0;
  otpRefCode: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private message: NzMessageService,
    private playerService: PlayersService,
  ) {}

  ngOnInit(): void {
    this.forgotPWForm = this.fb.group(
      {
        tel: [null, [Validators.required]],
        otp: [null, [Validators.required]],
        password: [null, [Validators.required]],
        confirm_password: [
          null,
          [Validators.required, this.confirmationValidator],
        ],
      },
      {
        validators: [ConfirmPasswordValidator.MatchPassword],
      }
    );
  }
  requestOTP() {
    this.playerService.playerRequestOtp(this.forgotPWForm.controls["tel"].value).subscribe((resp) => {
      if (resp) {
        this.otpRefCode = resp?.otpRefCode
        this.current = 1
      }
    });
  }
  nextStep() {
    const body: IPlayerVerifyOtp = {
      player_tel: this.forgotPWForm.controls["tel"].value,
      otp_code: this.forgotPWForm.controls["otp"].value,
      otp_ref_code: this.otpRefCode
    }
    this.playerService.playerVerifyOtp(body).subscribe((resp) => {
      if (resp) {
        this.current = 2
      }
    });
  }
  confirm() {
    const body: IPlayerResetPassword = {
      player_tel: this.forgotPWForm.controls["tel"].value,
      otp_code: this.forgotPWForm.controls["otp"].value,
      otp_ref_code: this.otpRefCode,
      password: this.forgotPWForm.controls["password"].value
    }
    this.playerService.playerResetPassword(body).subscribe((resp) => {
      this.message.success("เปลี่ยนรหัสผ่านสำเร็จ")
      this.handleCloseModal.emit();
    });
  }
  // --------------------------------
  confirmationValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.forgotPWForm.controls["password"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.forgotPWForm.controls["confirm_password"].updateValueAndValidity()
    );
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPWForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
