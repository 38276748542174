import { Component } from '@angular/core';

@Component({
  selector: 'app-table-winloss',
  templateUrl: './table-winloss.component.html',
  styleUrls: ['./table-winloss.component.scss']
})
export class TableWinlossComponent {

}
