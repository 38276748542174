import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-site-maintenance',
  templateUrl: './site-maintenance.component.html',
  styleUrls: ['./site-maintenance.component.scss']
})
export class SiteMaintenanceComponent implements OnInit {
  objData: any;
  startDate: string = '';
  startTime: string = '';
  endDate: string = '';
  endTime: string = '';
  detail: string = '';

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.startDate = '24/07/2566'
    this.startTime = '13:40'
    this.endDate = '24/07/2566'
    this.endTime = '23:59'
    this.detail = 'แจ้งปิดระบบชั่วคราวค่ะ'
  }
}
