<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <app-header-menu></app-header-menu>
  <!-- <app-banner></app-banner> -->
</ng-template>
<ng-template #content>
  <div class="minH50">
    <!-- <div class="search-box">
      <input nz-input placeholder="ค้นหาเกมที่นี่" />
    </div> -->
    <div class="panel-gray">
      <div class="title">
        <img src="assets/image/header.png" alt="" />
        <span class="title-text">{{ this.providerName }}</span>
      </div>
      <p class="content-description">
        บริการเดิมพันเกมยอดนิยม ด้วยระบบมาตรฐานระดับสากล รวดเร็ว ทันใจ แจกใหญ่
        แจกจริง
      </p>
      <div>
        <div class="game-grid">
          <div class="game-box" *ngFor="let item of listGames as folder">
            <!-- (click)="gotoLaunchGame(item)  -->
            <a
              class="game-item"
              *ngIf="this.isLogin"
              target="_blank"
              [routerLink]="['/launch', item.id]"
            >
              <div class="game-block">
                <img
                  [src]="item.game_image_url"
                  src-fallback="{{ this.imgError }}"
                  (loaded)="onLoaded($event)"
                  alt=""
                />
                <div class="game-hover">
                  <button class="btn-secondary">เข้าเล่น</button>
                </div>
              </div>
            </a>
            <a
              class="game-item"
              *ngIf="!this.isLogin"
              (click)="handleModalLogin()"
            >
              <div class="game-block">
                <img
                  [src]="item.game_image_url"
                  src-fallback="{{ this.imgError }}"
                  (loaded)="onLoaded($event)"
                  alt=""
                />
                <div class="game-hover">
                  <button class="btn-secondary">เข้าเล่น</button>
                </div>
              </div>
            </a>
            <div class="game-name">
              <span>{{ item.game_name_en }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- ======================================================== -->
<nz-modal
  [nzWidth]="350"
  nzClassName="bg-modal"
  [(nzVisible)]="isVisibleLogin"
  nzWrapClassName="modal-login"
  (nzOnCancel)="handleModalLogin()"
>
  <ng-container *nzModalContent>
    <div class="form-modal-panel">
      <app-form-login></app-form-login>
    </div>
  </ng-container>
</nz-modal>
