<app-section-bg [template]="content" [banner]="bannerContent"></app-section-bg>
<ng-template #bannerContent>
  <app-banner *ngIf="!isLogin"></app-banner>
</ng-template>
<ng-template #content>
  <div class="">
    <div class="container content-aff tx-yellow">
      <div class="title">
        <img src="assets/image/header.png" alt="" />
        <span class="title-text">หารายได้</span>
      </div>
      <p>
        สมาชิกทุกท่าน สามารถหารายได้ง่าย ๆ จากการทำกิจกรรม “แนะนำเพื่อน”
        เพียงแชร์ลิงค์ของตัวเองให้กับเพื่อน ๆ ได้เข้ามาร่วมสมัครสมาชิกกับเรา
        ผู้แนะนำจะมีสิทธิ์ได้รับ “ค่าคอมมิชชั่น”
        ในกรณีเพื่อนที่ท่านแนะนำได้ร่วมเดิมพัน หรือเล่นเกมต่าง ๆ
        ระบบจะดำเนินการจ่ายค่าคอมมิชชั่นตามเปอร์เซ็นที่ได้กำหนดไว้
      </p>
      <p>เงื่อนไขสำหรับพันธมิตรชวนเพื่อน หารายได้ฟรี !!</p>
      <ul class="list-decimal">
        <li>ใช้ลิงก์ด้านบนในการชวนเพื่อนสมัครสมาชิก</li>
        <li>
          ท่านสมาชิกจะได้รับคอมมิชชั่นจากสมาชิกที่เพื่อนของท่านชวนต่ออีก 1 ขั้น
          (ดาวน์ไลน์)
        </li>
        <li>
          ท่านสมาชิกจะได้รับค่าคอมมิชชั่นจากยอดเล่น
          (แต่ละชนิดจะได้รับคอมมิชชั่นไม่เท่ากัน)
        </li>
        <li>
          คอมมิชชั่นที่ได้รับจะสามารถโยกเป็นกระเป๋าเงินและถอนเงินได้โดยขั้นต่ำในการโยกคือ
          300 บาท
        </li>
      </ul>
      <div *ngIf="isLogin">
        <div class="content-flex">
          <div class="panel-box with-bg">
            <p class="center">กระเป๋ารายได้</p>
            <div>
              <div class="fx-input">
                <div class="mock-input">
                  {{ balance | currency : "" : "" }}
                  <div
                    nz-tooltip
                    class="btn-refresh"
                    nzTooltipPlacement="top"
                    (click)="getBalance()"
                    nzTooltipTitle="อัปเดตยอดเงิน"
                  >
                    <img src="assets/image/i-refresh.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="center m-10">
                <button
                  class="btn-green mb-10"
                  (click)="comfirm()"
                  [disabled]="this.isLoading"
                >
                  โยกเงิน
                </button>
              </div>

              <div class="fx-input">
                <label>รหัสแนะนำ: </label>
                <div class="mock-dis-input">{{ value }}</div>
                <button
                  class="btn-green m-10 btn-i-copy"
                  nz-typography
                  nzCopyable
                  [nzCopyText]="value"
                ></button>
              </div>
              <div class="fx-input">
                <label>ลิงต์บอกต่อ: </label>
                <div class="mock-dis-input">
                  <span>{{ linkInvite }}</span>
                </div>
                <button
                  class="btn-green m-10 btn-i-copy"
                  nz-typography
                  nzCopyable
                  [nzCopyText]="linkInvite"
                ></button>
              </div>
            </div>
          </div>
          <div class="panel-box with-bg">
            <p class="center">QR Code</p>
            <div class="mb-10 center">
              <!-- value="value" -->
              <qr-code size="300" [value]="linkInvite" errorCorrectionLevel="M">
              </qr-code>
            </div>
          </div>
        </div>

        <h3 class="tx-yellow mb-0 center">คอมมิชชั่นการเล่น</h3>
        <div class="panel-box">
          <app-table-type-commissions></app-table-type-commissions>
        </div>

        <h3 class="tx-yellow mb-0 center">ประวัติการทำรายการ</h3>
        <div class="panel-box">
          <app-table-history [transactions]="transactions"></app-table-history>
        </div>

        <h3 class="tx-yellow mb-0 center">ดาวน์ไลน์</h3>
        <div class="panel-box">
          <app-table-downline [downline]="this.downline"></app-table-downline>
        </div>

        <h3 class="tx-yellow mb-0 center">คอมมิชชั่นการเล่น</h3>
        <app-table-commissions></app-table-commissions>
      </div>
    </div>
  </div>

  <nz-modal
    [nzWidth]="380"
    nzClassName="bg-profile-modal"
    [(nzVisible)]="isVisibleConfirmDialog"
    nzWrapClassName="modal-submit modal-profile"
    nzOkText="ยืนยัน"
    nzCancelText="ยกเลิก"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleSubmit()"
    [nzOkLoading]="isOkLoading"
    [nzMaskClosable]="false"
  >
    <ng-container *nzModalContent>
      <div class="form-modal-panel tx-yellow">ยืนยันการโยกเงิน ?</div>
    </ng-container>
  </nz-modal>
</ng-template>
