import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RespDto } from "../_model/respDto";
import { LoginProfile } from "../_model/user";
import { JwtHelperService } from "@auth0/angular-jwt";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = environment.apiUrl + environment.playersApi;
  apiKey: string = environment.apiKey;
  agentCode: string = environment.agentCode;
  decodedToken: any;
  jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  login(username: string, password: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": this.apiKey,
    });
    return this.http
      .post(
        this.baseUrl + "/login",
        {
          username: username,
          password: password,
          code: this.agentCode,
        },
        {
          headers: httpHeaders,
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            localStorage.setItem("session", resp);
            localStorage.setItem("rt", resp.refresh_token);
            localStorage.setItem("token", resp.access_token);
          }
          return resp;
        })
      );
  }
  logout() {
      localStorage.clear();
      window.location.reload();
  }

  loggedIn() {
    const token = localStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token || "");
  }
  getDecodeToken() {
    const token = localStorage.getItem("token");
    return this.jwtHelper.decodeToken(token || "");
  }

  refreshToken(refresh_token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      "api-key": this.apiKey,
    });
    return this.http
      .post(
        this.baseUrl + "/refresh",
        {
          refresh_token: refresh_token,
        },
        {
          headers: httpHeaders,
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            localStorage.setItem("rt", resp.refresh_token);
            localStorage.setItem("token", resp.access_token);
          }
          return resp;
        })
      );
  }

  forgotPassword(userName: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    return this.http
      .post(
        this.baseUrl + "/ForgotPassword",
        {
          userName,
        },
        {
          headers: httpHeaders,
        }
      )
      .pipe(map((resp: RespDto) => resp as RespDto));
  }

  changePassword(password: string): Observable<any> {
    const token = localStorage.getItem("token");
    const httpHeaders = new HttpHeaders({
      Authorization: "Bearer" + token,
      "api-key": environment.apiKey,
    });
    return this.http.patch(
      this.baseUrl + "/v1/change/password",
      {
        password,
      },
      {
        headers: httpHeaders,
      }
    );
  }
}
