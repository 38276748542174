import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { ConfirmPasswordValidator } from "src/app/auth/confirm-password.validator";

@Component({
  selector: "app-modal-change-password",
  templateUrl: "./modal-change-password.component.html",
  styleUrls: ["./modal-change-password.component.scss"],
})
export class ModalChangePasswordComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  loading = false;
  errors: any = [];
  userDup = false;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private message: NzMessageService
  ) {}

  @Input() data: any;
  ngOnInit() {
    this.initRegisterForm();
  }

  initRegisterForm() {
    this.validateForm = this.fb.group(
      {
        password: [null, [Validators.required]],
        confirm_password: [
          null,
          [Validators.required, this.confirmationValidator],
        ],
      },
      {
        validators: [ConfirmPasswordValidator.MatchPassword],
      }
    );
  }
  confirmationValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls["password"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.validateForm.controls["confirm_password"].updateValueAndValidity()
    );
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.validateForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
