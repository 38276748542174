import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl | any) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirm_password').value;

    if (password !== confirmPassword) {
      return { ConfirmPassword: true };
    } else {
      return null;
    }
  }
}
