import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { GamesService } from "src/app/_service/games.service";
import { ImageGameService } from "src/app/_service/image-game.service";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent {
  @Input() showRegis: boolean = false;
  imgError = "assets/image/mock_game.png";
  isVisibleRegister = false;
  isVisibleLogin = false;
  isLogin: any;
  instance: any;
  listGame: any[] = [];
  gameTypeList: any[] = [];
  slotId: number = 0;
  liveId: number = 0;
  sportId: number = 0;
  isVisibleLists = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private message: NzMessageService,
    private gameService: GamesService,
    private imageGameService: ImageGameService
  ) {}

  async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);
    this.isLogin = this.authService.loggedIn();
    this.isVisibleRegister = !this.isLogin && this.showRegis;
    await this.getTypeId();
    // setTimeout(() => (this.isVisibleLists = true), 400);
  }
  getGame() {}
  getAllProviders() {}
  goto(action: string) {
    this.router.navigateByUrl("/" + action);
  }
  async getTypeId() {
    this.gameService.getGameType().subscribe(
      (resp: any) => {
        if (resp) {
          this.gameTypeList = resp;
          this.slotId = resp.find(
            (x: { game_type_code: string }) => x.game_type_code == "SLOT"
          ).id;
          this.liveId = resp.find(
            (x: { game_type_code: string }) => x.game_type_code == "LIVE"
          ).id;
          this.sportId = resp.find(
            (x: { game_type_code: string }) => x.game_type_code == "SPORT"
          ).id;
        }
      },
      (err: any) => {},
      () => {
        this.isVisibleLists = true;
      }
    );
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
  handleModalRegister() {
    this.isVisibleRegister = !this.isVisibleRegister;
  }
  // ------------------------------------------------------------
  // getImgProvider(provider: string) {
  //   let provImage = "";
  //   return (provImage = this.imageGameService.getImgProvider(provider));
  // }
  onLoaded(isFallback: boolean) {}
}
