import { Component } from '@angular/core';

@Component({
  selector: 'app-muay-thai',
  templateUrl: './muay-thai.component.html',
  styleUrls: ['./muay-thai.component.scss']
})
export class MuayThaiComponent {

}
