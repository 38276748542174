<nz-table
  #ajaxTable
  nzShowSizeChanger
  class="custom-table"
  [nzData]="dataSource"
  [nzLoading]="loading"
  [nzScroll]="{ x: '330px' }"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  <thead>
    <tr>
      <th nzWidth="30px"></th>
      <th nzWidth="100px">ประเภท</th>
      <th>ดาวน์ไลน์ LV1</th>
      <th>ดาวน์ไลน์ LV2</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of ajaxTable.data; let i = index">
      <tr>
        <td
          *ngIf="data.type !== 'LOTTO'"
          nzShowExpand
          [(nzExpand)]="data.isExpand"
          (nzExpandChange)="getDownline(data.isExpand, data)"
        ></td>
        <td *ngIf="data.type === 'LOTTO'"></td>
        <td class="tx-yellow">{{ data.type }}</td>
        <td>{{ data.lv1 }}</td>
        <td>{{ data.lv2 }}</td>
      </tr>
      <!-- ----------------------------------------------------------------------------------- -->
      <tr [nzExpand]="data.isExpand" class="padExpan0">
        <nz-table
          #subTable
          [nzLoading]="loading"
          [nzData]="data.listSubOfData"
          [nzShowPagination]="false"
          [nzFrontPagination]="false"
        >
          <thead>
            <tr>
              <th></th>
              <th>เกม</th>
              <th>ดาวน์ไลน์ LV1</th>
              <th>ดาวน์ไลน์ LV2</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let subItem of subTable.data; let i = index">
              <td></td>
              <td>{{ subItem.gameProvider }}</td>
              <td>{{ subItem.lv1 }}</td>
              <td>{{ subItem.lv2 }}</td>
            </tr>
          </tbody>
        </nz-table>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
