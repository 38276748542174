<div class="content-profile">
  <nz-tabset class="custom-tab" [nzSelectedIndex]="this.tabId">
    <nz-tab nzTitle="ฝาก">
      <app-modal-deposit></app-modal-deposit>
    </nz-tab>
    <nz-tab nzTitle="ถอน">
      <app-modal-withdraw></app-modal-withdraw>
    </nz-tab>
  </nz-tabset>

  <span class="detail-contact">
    กรณีฝาก/ถอน แล้วเงินไม่เข้าภายใน 5 นาที
    <a class="tx-red underline display-block fix-block">
      ติดต่อฝ่ายบริการลูกค้า
    </a>
  </span>
</div>
