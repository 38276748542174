export class LoginProfile {
  access_token: string = "";
  expires_in: number = 0;
  refresh_expires_in: number = 0;
  refresh_token: string = "";
  token_type: string = "";
  session_state: string = "";
  scope: string = "";
}
export class UserRegister {
  username: string = "";
  password: string = "";
  tel: string = "";
  name: string = "";
  surname: string = "";
  email: string = "";
  code: string = "";
  credit: number = 0;
  status: string = "";
  is_validate_bank_account: boolean = false;
  bank_accounts?: BankAccounts;
  register_revenue_configuration?: RegisterRevenue;
  seller_code: string = "";
}

export class BankAccounts {
  bank_name: string = "";
  account_no: string = "";
  account_name: string = "";
  account_type: string = "";
  is_active: boolean = false;
  prompt_pay_no: string = "";
}
export class RegisterRevenue {
  game_type: string = "";
  configuration?: Configuration;
}
export class Configuration {
  provider_game: string = "";
  status: boolean = false;
  receive: number = 0;
  force: number = 0;
  remain: number = 0;
}

export class WithdrawStatus {
  status: string = "";
  message: [] = [];
  max_withdraw_credit_amount: number = 0;
  min_withdraw_credit_amount: number = 0;
  is_withdraw_all_credit: boolean = false;
}

export class GameType {
  id: number = 0;
  game_type_code: string = "";
  game_type_desc_th: string = "";
  game_type_desc_en: string = "";
  created_by: string = "";
  created_date: string = "";
  updated_by: string = "";
  updated_date: string = "";
}
