<footer>
  <div class="about-section">
    <div class="bg-texture">
      <div class="about-img1">
        <img src="assets/image/piramid.png" alt="" />
      </div>
      <div class="about-img2">
        <img src="assets/image/pharaoh.png" alt="" />
      </div>
      <div class="container flex about z-10">
        <div class="line-section">
          <!-- <div class="qr-section">
            <img src="assets/image/qr.png" class="about-qr z-10" alt="" />
          </div> -->
          <div class="about-line flex">
            <img src="assets/image/line.png" class="line-logo" alt="" />
            <a [href]="lineLink" class="line-text" target="_blank">
              <span>{{ lineId }}</span>
            </a>
          </div>
        </div>
        <div class="about-text">
          <p class="">
            Pharaoh888 ผู้ให้บริการสล็อตออนไลน์ มีเกมส์สล็อตมากที่สุดในประเทศไทย
            เรามีเกมสล็อต, ยิงปลาและเกมอาเขต มากกว่า 2000 เกมจาก 15 ค่ายชั้นนำ
            PGslot, JOKER slot, slotXO, JDB, Jumbo, Nikigame, CQ9, PlayStar,
            BetSoft, PlayTech, JILI, KA, Simple Play, Gaming Soft และเกมอื่นๆ
            ยอดนิยม เช่น เกมสล็อตโรม่า Roma เกมยิงจรวด แกมยิงปลา Jackpot
            Fishing, Cai Shen Fishing, สล็อตผลไม้, สล็อตแจ้คพ้อต jackpot slot,
            เกมฟรีสปิน FREESPIN, เกม Sugar pop, เกมเปิดกระโปรง, เกมจากการ์ตูน
            อนิเมะดัง one piece, ดราก้อนบอล, เซนต์เซย่า, one punch man,
            Slamdunk, เกมคีบตุ๊กตา เกมสล็อตใหม่ล่าสุด
            เว็บสล็อตตรงไม่ผ่านเอเย่นต์ต้องมาที่ WinWorld888 สล็อตได้เงินจริง
            สล็อตบนมือถือ สล็อตออนไลน์ slot online, แจกเครดิตฟรีทุกวัน
            เซียนสล็อต มีสูตรสล็อตต้องลอง บริการเกมสล็อตทั้งหมด
            ด้วยระบบฝากถอนออโต้ ปลอดภัยมั่นคง รวดเร็ว ไม่ต้องส่งสลิป ภายใน 10
            วินาที โบนัส โปรโมชั่น เยอะมากมาย สมัครใหม่รับโบนัส 77% 777 บาท,
            ค่าคอมไม่จำกัด 0.7%, คืนยอดเสีย
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <p>
      © {{ this.date | date : "yyyy" }} Pharaoh888 สงวนลิขสิทธิ์
      สินค้าและบริการของ Pharaoh888
      มีใบอนุญาตและได้รับการตรวจสอบจากรัฐบาลฟิลิปปินส์และ First Cagayan
    </p>
    <p>version {{ this.version }}</p>
  </div>
</footer>
