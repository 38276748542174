<div nz-row nzGutter="8">
  <div nz-col nzSpan="24" class="label-pad">
    <form nz-form [formGroup]="validateForm" nzLayout="vertical">
      <div>
        <nz-form-item>
          <nz-form-label nzFor="password">รหัสผ่านใหม่</nz-form-label>
          <nz-form-control [nzErrorTip]="errorTpl1">
            <input
              nz-input
              minlength="6"
              maxlength="20"
              type="password"
              formControlName="password"
              (ngModelChange)="updateConfirmValidator()"
            />
            <ng-template #errorTpl1 let-control>
              <ng-container *ngIf="control.hasError('required')"
                >กรุณาระบุ</ng-container
              >
              <ng-container *ngIf="control.hasError('minlength')"
                >ระบุรหัสผ่านอย่างน้อย 6 หลัก</ng-container
              >
              <ng-container *ngIf="control.hasError('maxlength')"
                >รหัสผ่านต้องไม่เกิน 100 หลัก</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <nz-form-item>
          <nz-form-label nzFor="confirm_password">ยืนยันรหัสผ่าน</nz-form-label>
          <nz-form-control [nzErrorTip]="errorTpl">
            <input
              nz-input
              minlength="6"
              maxlength="20"
              type="password"
              formControlName="confirm_password"
            />
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >กรุณาระบุ</ng-container
              >
              <ng-container *ngIf="control.hasError('minlength')"
                >ระบุรหัสผ่านอย่างน้อย 6 หลัก</ng-container
              >
              <ng-container *ngIf="control.hasError('maxlength')"
                >รหัสผ่านต้องไม่เกิน 100 หลัก</ng-container
              >
              <ng-container *ngIf="control.hasError('confirm')"
                >รหัสผ่านไม่ตรงกัน</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </div>
</div>
