import { Component } from '@angular/core';

@Component({
  selector: 'app-table-bet',
  templateUrl: './table-bet.component.html',
  styleUrls: ['./table-bet.component.scss']
})
export class TableBetComponent {

}
