import { Component, OnInit, Input } from "@angular/core";
import { UtilService } from "src/app/_service/util.service";

@Component({
  selector: "app-table-history",
  templateUrl: "./table-history.component.html",
  styleUrls: ["./table-history.component.scss"],
})
export class TableHistoryComponent implements OnInit {
  @Input() transactions: any;
  listOfData: any[] = [];
  constructor(public util: UtilService) {}

  ngOnInit(): void {}
}
