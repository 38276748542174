import { Component, OnInit, Input,ElementRef } from "@angular/core";
import { ImageGameService } from "src/app/_service/image-game.service";
import { GamesService } from "src/app/_service/games.service";
import { GameType } from "src/app/_model/user";
import { CookieUtilService } from "src/app/_service/cookie-util.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_service/auth.service";

@Component({
  selector: "app-game-list",
  templateUrl: "./game-list.component.html",
  styleUrls: ["./game-list.component.scss"],
})
export class GameListComponent implements OnInit {
  @Input() checkType!: string;
  imgError = "assets/image/mock_game.png";
  isLogin: boolean = false;
  isVisibleLogin = false;
  listGames: any[] = [];
  gameTypeId = 0;
  gameId = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private imageGameService: ImageGameService,
    public cookieUtilService: CookieUtilService,
    private gamesService: GamesService,
    private elementRef: ElementRef
  ) {}
  ngAfterViewInit(): void {}
  async ngOnInit(): Promise<void> {
    this.isLogin = this.authService.loggedIn();
    await this.getTypeId(this.checkType);
  }
  async hamdleGameType(type: string) {
    await this.getTypeId(type);
  }
  async getTypeId(type: string) {
    this.checkType = type;
    this.gamesService.getGameType().subscribe((resp: any) => {
      if (resp) {
        resp.forEach((i: GameType) => {
          if (i.game_type_code.toUpperCase() == type.toUpperCase()) {
            this.gameTypeId = i.id;
          }
        });
        this.getProvider(this.gameTypeId);
      }
    });
  }
  async getProvider(id: number) {
    this.gamesService.getProviderByGameType(id).subscribe((resp: any) => {
      if (resp) {
        this.listGames = resp;
      }
    });
  }
  // ------------------------------------------------------------
  gotoLaunchGame(item: any) {
    if (["SLOT", "LOTTO"].includes(this.checkType)) {
      this.router.navigateByUrl("/game/" + item.game_provider_code);
    } else {
      if (this.isLogin) {
        this.gamesService.getGameByProvider(item.game_provider_code).subscribe((resp: any) => {
          if (resp) {
            resp.forEach((item: any) => {
              this.gameId = item.id;
                const anchor = this.elementRef.nativeElement.querySelector(
                  "#newTabGotoLaunchGame"
                );
                if (anchor) {
                  setTimeout(function () {
                    anchor.click();
                  }, 1);
                }
            });
          }
        });
      } else {
        this.isVisibleLogin = true;
      }
    }
  }
  getImgProvider(provider: string) {
    let provImage = "";
    return (provImage = this.imageGameService.getImgProvider(
      provider,
      this.checkType
    ));
  }
  onLoaded(isFallback: boolean) {
    // make somthing based on 'isFallback'
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
}
