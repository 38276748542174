import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { PlayersService } from "src/app/_service/players.service";
import { AuthService } from "src/app/_service/auth.service";
import { CookieUtilService } from "src/app/_service/cookie-util.service";

@Component({
  selector: "app-section-bg",
  templateUrl: "./section-bg.component.html",
  styleUrls: ["./section-bg.component.scss"],
})
export class SectionBgComponent implements OnInit {
  @Input() template!: TemplateRef<any> | null;
  @Input() banner!: TemplateRef<any> | null;
  isLogin = false;
  lineLink = "";
  lineId = "";

  constructor(
    private authService: AuthService,
    private playersService: PlayersService,
    public cookieUtilService: CookieUtilService
  ) {
    this.isLogin = this.authService.loggedIn();
  }

  ngOnInit(): void {
    this.getLineInfo();
  }
  getLineInfo() {
    this.playersService.getLineInfo().subscribe((resp: any) => {
      if (resp) {
        this.lineLink = resp.line_url;
        this.lineId = resp.line_id;
      }
    });
  }
}
