import { Component, Input, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-section-islogin',
  templateUrl: './section-islogin.component.html',
  styleUrls: ['./section-islogin.component.scss']
})
export class SectionIsloginComponent {
  @Input() template!: TemplateRef<any> | null;
  isLogin = false;

  constructor(
    private authService: AuthService
  ) {
    this.isLogin = this.authService.loggedIn();
  }

}
