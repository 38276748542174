<div class="nav-menu -login" *ngIf="isLogin" [ngClass]="isVisible ? 'open' : ''">
    <div>
        <i nz-icon nzType="close" nzTheme="outline" (click)="showNav()" class="icon-close"></i>
    </div>
    <p class="nav-menu-title">Menu</p>
    <div class="list-menu">
        <div class="list-img" [ngClass]="{ act: checkPath === '/history' }" (click)="goto('history')">
            <img src="assets/image/nav/nav-m-promotion.svg" alt="">
        </div>
        <label>ประวัติ</label>
    </div>
    <div class="list-menu">
        <div class="list-img" [ngClass]="{ act: checkPath === '/promotion' }" (click)="goto('promotion')">
            <img src="assets/image/nav/nav-m-promotion.svg" alt="">
        </div>
        <label>โปรโมชั่น</label>
    </div>
    <div class="list-menu">
        <div class="list-img" [ngClass]="{ act: checkPath === '/deposit' }" (click)="goto('deposit')">
            <img src="assets/image/nav/nav-m-deposit.svg" alt="">
        </div>
        <label>ฝาก</label>
    </div>
    <div class="list-menu">
        <div class="list-img" [ngClass]="{ act: checkPath === '/withdraw' }" (click)="goto('withdraw')">
            <img src="assets/image/nav/nav-m-withdraw.svg" alt="">
        </div>
        <label>ถอน</label>
    </div>
    <div class="list-menu">
        <div class="list-img" [ngClass]="{ act: checkPath === '/spin' }" (click)="goto('spin')">
            <img src="assets/image/nav/nav-m-wheel.svg" alt="">
        </div>
        <label>Lucky Wheel</label>
    </div>
    <div class="list-menu">
        <div class="list-img" (click)="openDialogChangePassword()">
            <img src="assets/image/nav/nav-m-key.svg" alt="">
        </div>
        <label>เปลี่ยนรหัสผ่าน</label>
    </div>
    <p class="-version">v{{ this.version }}</p>
</div>

<!-- <div class="nav-menu -nologin" *ngIf="!isLogin" [ngClass]="isVisible ? 'open' : ''">
    <div>
        <i nz-icon nzType="close" nzTheme="outline" (click)="showNav()" class="icon-close"></i>
    </div>
    <label>หน้าแรก</label>
    <label>เรตมวย</label>
    <label>มวยวันนี้</label>
    <label>ผลมวยล่าสุด</label>
    <label>มวยไทย</label>
    <label>ดูมวยสด</label>
    <label>เว็บมวย</label>
</div> -->
<div class="nav-left-panel-bg" (click)="showNav()" [ngClass]="isVisible ? 'open' : ''"></div>
