import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { GamesService } from "src/app/_service/games.service";

@Component({
  selector: "app-launch-game",
  templateUrl: "./launch-game.component.html",
  styleUrls: ["./launch-game.component.scss"],
})
export class LaunchGameComponent implements OnInit {
  user: any;

  constructor(
    private gameService: GamesService,
    private message: NzMessageService,
    private authService: AuthService,
    private actRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getDecodeToken();
    this.actRoute.paramMap.subscribe((params: any) => {
      const id = params.get("id");
      this.gameService.launchGame(Number(id), this.user.id).subscribe((resp: any) => {
        if (resp) {
          window.location.href = resp.game_url;
        }
      });
    });
  }
}
