declare var require: any;
const version = require("../../package.json").version;
export const environment = {
  appVersion: `${version}`,
  production: false,
  isMockEnabled: false,
  agentCode: "ATA1",
  apiKey: "FABD3928CB3245DFA20C28C6A4820C03",
  apiUrl: "https://dev-api.atgame-asia.com",
  webUrl: "https://gr77.win",
  domainApi: "dev-api.atgame-asia.com",
  urlImgCDN: "https://dev-cdn.sgp1.cdn.digitaloceanspaces.com/images",
  lineLink: "https://lin.ee/0L5KsBf",
  lineID: "@672mdqej",
  playersApi: "/players",
  gamesApi: "/games",
  promotionsApi: "/promotions/v1",
};
