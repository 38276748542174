<div class="date-m-grid">
  <form
    nz-form
    nz-row
    [nzGutter]="16"
    nzLayout="horizontal"
    [formGroup]="searchForm"
  >
    <nz-form-control class="mem-form-date">
      <label class="label-mr">วันที่เริ่มต้น</label>
      <nz-date-picker
        class="mem-date"
        [nzDisabledDate]="disabledStartDate"
        nzFormat="dd/MM/yyyy"
        [(ngModel)]="startValue"
        nzPlaceHolder="เริ่มต้น"
        formControlName="startDate"
        (ngModelChange)="onStartChange($event)"
        (nzOnOpenChange)="handleStartOpenChange($event)"
      >
      </nz-date-picker>
      <!-- <nz-range-picker
        class="mem-date"
        nzFormat="dd/MM/yyyy"
        [(ngModel)]="startValue"
        nzPlaceHolder="เริ่มต้น"
        formControlName="startDate"
        [nzDisabledDate]="disabledStartDate"
        (ngModelChange)="onStartChange($event)"
        (nzOnOpenChange)="handleStartOpenChange($event)"
      >
      </nz-range-picker> -->
    </nz-form-control>

    <nz-form-control class="mem-form-date">
      <label class="label-mr">วันที่สิ้นสุด</label>
      <nz-date-picker
        class="mem-date"
        nzFormat="dd/MM/yyyy"
        nzPlaceHolder="สิ้นสุด"
        formControlName="endDate"
        [(ngModel)]="endValue"
        [nzDisabledDate]="disabledEndDate"
        (ngModelChange)="onEndChange($event)"
        (nzOnOpenChange)="handleEndOpenChange($event)"
      >
      </nz-date-picker>
    </nz-form-control>
    <button
      nz-button
      class="btn-primary"
      nzType="primary"
      [nzLoading]="this.loading"
      (click)="getDataHistory(this.startValue, this.endValue)"
    >
      <i nz-icon nzType="search"></i>ค้นหา
    </button>
  </form>
</div>
<div class="panel-box">
  <div>
    <nz-table
      #fixedTable
      #ajaxTable
      nzSize="small"
      nzShowSizeChanger
      [nzTotal]="total"
      [nzData]="dataSource"
      [nzLoading]="loading"
      [nzScroll]="{ x: '330px' }"
      [nzFrontPagination]="false"
      class="custom-table"
    >
      <thead>
        <tr>
          <th></th>
          <th>ยอด</th>
          <th>คอมมิชชั่น</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of dataSource">
          <td>{{ data.text }}</td>
          <td>{{ data.amount }}</td>
          <td>{{ data.commission }}</td>
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td>รวม</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>
        </tr>
      </tfoot> -->
    </nz-table>
  </div>
</div>
