<div class="title">
  <img src="assets/image/header.png" alt="" />
  <span class="title-text">ถอน</span>
</div>
<p class="tx-yellow">
  <b>ระบุจำนวนเงินถอน</b>
</p>
<div class="box-bank" [ngClass]="showWarning ? '-err' : ''">
  <nz-input-number
    [nzPrecision]="2"
    [nzStep]="0.1"
    [nzParser]="parser"
    [nzFormatter]="formatter"
    nzPlaceHolder="ระบุจำนวนเงิน"
    [(ngModel)]="withdrawAmount"
    (ngModelChange)="this.changeAmount()"
    [disabled]="isWithdrawAll"
  >
  </nz-input-number>
</div>
<div class="center">
  <button
    class="btn-primary m-10"
    (click)="confirm()"
    [disabled]="this.isDisabled"
  >
    แจ้งถอนเงิน
  </button>
  <p class="tx-yellow">
    ขั้นตํ่า {{ this.minValue | currency : "" : "" }} THB สูงสุด
    {{ this.maxValue | currency : "" : "" }} THB
  </p>
</div>
<div *ngIf="!this.isLoading">
  <div
    class="message-box"
    *ngIf="this.massage && this.massage.length && this.massage.length > 0"
  >
    <div *ngFor="let item of this.massage as array">
      <span>{{ item }}</span>
    </div>
  </div>
</div>

<div class="m-10">
  <nz-table
    #basicTable
    class="custom-table"
    [nzData]="listOfData ? listOfData : []"
    [nzFrontPagination]="false"
    [nzScroll]="listOfData.length > 10 ? { y: '280px' } : {}"
  >
    <thead>
      <tr>
        <th nzWidth="120px">วันที่ถอน</th>
        <th class="tx-r">จำนวนเงิน</th>
        <th nzWidth="">สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ this.util.showToJsonDateTime(data.transaction_time) }}</td>
        <td class="tx-r">{{ data.amount | currency : "" : "" }}</td>
        <td [ngClass]="this.util.colorOfStatus(data.status)">
          {{ this.util.textTranslate(data.status) }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
<div class="mt-30">
  <div class="foot">
    <div>
      <span class="tx-white"
        >พบปัญหา
        <a
          [href]="lineLink"
          target="_blank"
          class="tx-yellow underline display-block fix-block"
          >ติดต่อฝ่ายบริการลูกค้า</a
        >
      </span>
    </div>
  </div>
</div>
