<app-section-islogin [template]="content"></app-section-islogin>

<ng-template #content>
    <div class="bg-page">
        <div class="container">
            <div class="content-page">
                <app-modal-financial [isTitle]="false"></app-modal-financial>
            </div>
        </div>
    </div>
</ng-template>