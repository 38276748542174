<nz-table
  #basicTable
  class="custom-table"
  [nzData]="downline"
  [nzScroll]="{ x: '330px' }"
  [nzFrontPagination]="false"
>
  <thead>
    <tr>
      <th nzWidth="70px">#</th>
      <th>ดาวน์ไลน์</th>
      <th>AFF</th>
      <th>วันสร้าง</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data; let k = index">
      <td>{{ k + 1 }}</td>
      <td>{{ data.username }}</td>
      <td>level {{ data.level }}</td>
      <td>{{ data.created_at | date : "dd/MM/yyyy HH:mm" }}</td>
    </tr>
  </tbody>
</nz-table>
