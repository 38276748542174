import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_service/auth.service';
import { MockDataService } from 'src/app/_service/mock-data.service';

@Component({
  selector: 'app-table-type-commissions',
  templateUrl: './table-type-commissions.component.html',
  styleUrls: ['./table-type-commissions.component.scss']
})
export class TableTypeCommissionsComponent implements OnInit {
  dataSource: any[] = [];
  total: any;
  user: any;
  loading = false;


  constructor(
    private authService: AuthService,
    private mockDataService: MockDataService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getDecodeToken();
    this.getData();
  }
  getData() {
    this.mockDataService.getAffiliate().subscribe((resp: any) => {
      if (resp) {
        this.dataSource = [];
        resp.data.forEach((ele: any) => {
          this.dataSource.push({
            ...ele,
            isExpand: false
          })
        });
      }
    })
  }

  expandSet = new Set<string>();
  getDownline(isExpand: any, data: any) {
    data.isExpand = isExpand;
    this.loading = false;
    if (isExpand) {
      this.mockDataService.getDownlineAffiliate(data.type).subscribe((resp: any) => {
        if (resp) {
          data.listSubOfData = [];
          resp.data.forEach((ele: any) => {
            data.listSubOfData.push({
              ...ele,
              isExpand2: false
            })
          });
        }
      })
    }
  }
}
