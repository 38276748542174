import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class CookieUtilService {
  constructor(private cookieService: CookieService) { }

  setCookie(name: string, value: string, exp?: number | Date): void {
    this.cookieService.set(name, value, exp, '/');
  }

  cookieExists(name: string) {
    return this.cookieService.check(name);
  }

  getCookie(name: string) {
    return this.cookieService.get(name);
  }

  delCookie(name: string) {
    return this.cookieService.delete(name);
  }
}
