import { Component, OnInit, Input } from "@angular/core";
import { ImageGameService } from "src/app/_service/image-game.service";
import { GamesService } from "src/app/_service/games.service";
import { GameType } from "src/app/_model/user";
import { CookieUtilService } from "src/app/_service/cookie-util.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/_service/auth.service";

@Component({
  selector: "app-game-list-by-id",
  templateUrl: "./game-list-by-id.component.html",
  styleUrls: ["./game-list-by-id.component.scss"],
})
export class GameListByIdComponent {
  @Input() checkType!: string;
  @Input() typeId!: number;
  imgError = "assets/image/mock_game.png";
  isLogin: boolean = false;
  isVisibleLogin = false;
  listGames: any[] = [];
  gameTypeId = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private imageGameService: ImageGameService,
    public cookieUtilService: CookieUtilService,
    private gamesService: GamesService
  ) {}
  ngAfterViewInit(): void {}
  async ngOnInit(): Promise<void> {
    this.isLogin = this.authService.loggedIn();
    this.getProvider(this.typeId);
  }

  async hamdleGameType(type: string) {
    await this.getTypeId(type);
  }
  async getTypeId(type: string) {
    this.checkType = type;
    this.gamesService.getGameType().subscribe((resp: any) => {
      if (resp) {
        resp.forEach((i: GameType) => {
          if (i.game_type_code.toUpperCase() == type.toUpperCase()) {
            this.gameTypeId = i.id;
          }
        });
        this.getProvider(this.gameTypeId);
      }
    });
  }
  async getProvider(id: number) {
    this.gamesService.getProviderByGameType(id).subscribe((resp: any) => {
      if (resp) {
        this.listGames = resp;
      }
    });
  }
  // ------------------------------------------------------------
  gotoLaunchGame(item: any) {
    if (this.checkType === "SLOT") {
      this.router.navigateByUrl("/game/" + item.game_provider_code);
    } else if (this.checkType === "LIVE") {
      this.router.navigateByUrl("/game/" + item.game_provider_code);
    } else if (this.checkType === "SPORT") {
      this.router.navigateByUrl("/game/" + item.game_provider_code);
    } else if (this.checkType === "LOTTO") {
      this.router.navigateByUrl("/game/" + item.game_provider_code);
    }
    //   if (this.isLogin) {
    //     const url = this.router.serializeUrl(
    //       this.router.createUrlTree(["/launch/" + item.id])
    //     );
    //     window.open(url, "_blank");
    //   } else {
    //     this.isVisibleLogin = true;
    //   }
    // }
  }
  getImgProvider(provider: string) {
    let provImage = "";
    return (provImage = this.imageGameService.getImgProvider(
      provider,
      this.checkType
    ));
  }
  onLoaded(isFallback: boolean) {
    // make somthing based on 'isFallback'
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
}
