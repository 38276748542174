import { Component, OnInit, Input } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/_service/auth.service";
import { MockDataService } from "src/app/_service/mock-data.service";
import { GamesService } from "src/app/_service/games.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-game-slot-id",
  templateUrl: "./game-slot-id.component.html",
  styleUrls: ["./game-slot-id.component.scss"],
})
export class GameSlotIdComponent implements OnInit {
  listGames: any[] = [];
  param = "";
  isVisibleLogin = false;
  isLogin: boolean = false;
  imgError = "assets/image/mock_game.png";
  providerName = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private message: NzMessageService,
    private gameService: GamesService,
    private route: ActivatedRoute,
    private mockDataService: MockDataService
  ) {}
  // ------------------------------------------------------------
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isLogin = this.authService.loggedIn();
    this.route.params.subscribe((params: any) => {
      if (params.param != undefined) {
        this.param = params.param.toUpperCase();
      } else {
        this.param = "";
      }
      this.getGame(this.param);
    });
    this.getProviderName(this.param);
  }
  getGame(gameProvider: string) {
    this.listGames = [];
    this.gameService.getGameByProvider(gameProvider).subscribe((resp: any) => {
      if (resp) {
        resp.forEach((item: any) => {
          this.listGames.push(item);
        });
      }
    });

    // this.gameService.getGameByProvider(gameProvider).subscribe({
    //   next: (resp: any) => {
    //     resp.forEach((item: any) => {
    //       this.listGames.push(item);
    //     });
    //   },
    //   error: (e) => {
    //     this.message.error(e.error.display_message);
    //   },
    //   complete: () => { },
    // })
  }
  getProviderName(code: string) {
    this.gameService.getAllProviders().subscribe((resp: any) => {
      if (resp) {
        resp.forEach((item: any) => {
          if (item.game_provider_code === code) {
            this.providerName = item.game_provider_name_en;
          }
        });
      }
    });
  }
  // ------------------------------------------------------------
  gotoLaunchGame(item: any) {
    if (this.isLogin) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/launch/" + item.id])
      );
      window.open(url, "_blank");
    } else {
      this.isVisibleLogin = true;
    }
  }
  // ------------------------------------------------------------
  handleModalLogin() {
    this.isVisibleLogin = !this.isVisibleLogin;
  }
  // ------------------------------------------------------------
  onLoaded(isFallback: boolean) {
    // make somthing based on 'isFallback'
  }
}
